import { useCallback, useMemo } from "react";
import classNames from "classnames";
import { isNil } from "lodash-es";

import {
  Button,
  ButtonProps,
  Classes,
  InputGroup,
  // InputGroupProps,
} from "@blueprintjs/core";
import { Select2, ItemRenderer } from "@blueprintjs/select";
import { MenuItem2 } from "@blueprintjs/popover2";

export type SelectOptionType = Record<string, any>;
export interface SelectProps extends ButtonProps {
  value?: string | number;
  items: SelectOptionType[];
  labelKey?: string;
  valueKey?: string;
  placeholder?: string;
  onChange?: (value: string) => void;
  // inputProps?: InputGroupProps;
  allowClear?: boolean;
  readOnly?: boolean;
}

const TypedSelect = Select2.ofType<SelectOptionType>();

const Select = ({
  disabled,
  readOnly,
  value,
  items,
  labelKey = "label",
  valueKey = "value",
  placeholder = "输入名称搜索",
  // inputProps,
  allowClear,
  onChange,
  ...buttonProps
}: SelectProps) => {
  const renderItems = useMemo(() => {
    if (valueKey === "value" && labelKey === "label") return items;
    return items.map((item) => ({
      label: item[labelKey],
      value: item[valueKey],
    }));
  }, [items, valueKey, labelKey]);
  const handleIconChange = useCallback(
    (value: SelectOptionType) => {
      if (typeof onChange === "function") {
        onChange(value.value);
      }
    },
    [onChange]
  );
  const itemPredicate = useCallback(
    (query: string, iconName: SelectOptionType) => {
      if (!query) return true;
      return iconName.label.toLowerCase().includes(query.toLocaleLowerCase());
    },
    []
  );

  const itemRenderer = useCallback<ItemRenderer<SelectOptionType>>(
    (icon, { handleClick, handleFocus, modifiers }) => {
      if (!modifiers.matchesPredicate) return null;
      return (
        <MenuItem2
          selected={modifiers.active}
          key={icon.value}
          onClick={handleClick}
          onFocus={handleFocus}
          text={icon.label}
        />
      );
    },
    []
  );

  const getOptionDetail = (value: string | number) => {
    return renderItems.find((item) => item.value === value);
  };

  if (readOnly)
    return (
      <InputGroup
        className={classNames(
          Classes.TEXT_OVERFLOW_ELLIPSIS,
          buttonProps.className,
          "flex-1"
        )}
        small={buttonProps.small}
        readOnly
        placeholder={placeholder}
        value={getOptionDetail(value ?? "")?.label ?? value}
      />
    );
  return (
    <TypedSelect
      disabled={disabled}
      items={renderItems}
      itemPredicate={itemPredicate}
      itemRenderer={itemRenderer}
      noResults={<MenuItem2 disabled text="无匹配结果" />}
      onItemSelect={handleIconChange}
      popoverProps={{
        minimal: true,
        position: "bottom-left",
        matchTargetWidth: true,
      }}
      inputProps={Object.assign({}, { placeholder })}
    >
      {" "}
      {allowClear && !isNil(value) && value !== "" ? (
        <Button
          {...buttonProps}
          alignText="left"
          className={classNames(
            Classes.TEXT_OVERFLOW_ELLIPSIS,
            buttonProps.className
          )}
          disabled={disabled}
          fill={true}
          text={getOptionDetail(value)?.label ?? (value || placeholder)}
          rightIcon="cross"
          onClick={() => handleIconChange({ value: "", label: "" })}
        />
      ) : (
        <Button
          {...buttonProps}
          alignText="left"
          className={classNames(
            Classes.TEXT_OVERFLOW_ELLIPSIS,
            buttonProps.className
          )}
          disabled={disabled}
          fill={true}
          text={!value ? placeholder : getOptionDetail(value)?.label ?? value}
          rightIcon="caret-down"
        />
      )}
    </TypedSelect>
  );
};

export default Select;
