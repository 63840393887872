import request from "@/shared/libs/axios";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import type { NavigationType } from ".";
import { deepSort, arrayToTree } from "@/shared/utils/array";
import { z } from "zod";

import { DictType } from "@/shared/api/system/dict";
import { DataType as ProfitCenterType } from "@/shared/api/system/profitCenter";
import { DataType as WarehouseType } from "@/shared/api/basic/warehouse";

export const getMenuList = async (): Promise<NavigationType[]> => {
  return request.post<NavigationType[]>("/sys/menu/list").then((menuList) => {
    const newMenuList = arrayToTree(menuList, "id", "parentId");
    return deepSort(newMenuList, "children", (a, b) => {
      return b.orderNum - a.orderNum;
    });
  });
};

export const getMenuInfo = (id: string) => {
  return request.get<NavigationType>(`/sys/menu/info/${id}`);
};

export const menuSchema = z.object({
  id: z.string().optional(),
  name: z.string().min(1, "菜单名称不能为空"),
  parentId: z.string().default(""),
  path: z.string().default(""),
  perms: z.string().default(""),
  component: z.string().default(""),
  icon: z.string().default(""),
  type: z.number(),
  orderNum: z.number().default(0),
  redirectUrl: z.string().default(""),
  remark: z.string().default(""),
  alwaysShow: z.boolean().default(false),
  noCache: z.boolean().default(false),
  noMenu: z.boolean().default(false),
  noTags: z.boolean().default(false),
});

export type MenuEditDTO = z.infer<typeof menuSchema>;

export const editMenu = (values: Partial<MenuEditDTO>) => {
  const isCreate = !values.id;
  return request.post(`/sys/menu/${isCreate ? "save" : "update"}`, values);
};

export const deleteMenu = (id: string) => {
  return request.get(`/sys/menu/deleteById/${id}`);
};

/** 获取当前登录用户的允许查看分支列表 */
export const findCurrentUserAllowProfit = () => {
  return request.post<ProfitCenterType[]>(
    `/sys/menu/findCurLoginUserControlProfit`
  );
};

/** 获取当前登录用户的允许查看的仓库 */
export const findCurrentUserAllowWarehouse = () => {
  return request.post<WarehouseType[]>(
    `/sys/menu/findCurLoginUserControlWarehouse`
  );
};

/** 获取当前登录用户的允许查看的字典 */
export const findCurLoginUserControlDict = () => {
  return request.post<DictType[]>(`/sys/menu/findCurLoginUserControlDict`);
};

export const useList = () => {
  return useQuery(["menu-list"], getMenuList);
};

export const useInfo = (
  id: string,
  options?: Omit<
    UseQueryOptions<NavigationType, { msg: string }, NavigationType, string[]>,
    "initialData" | "queryFn" | "queryKey"
  >
) => {
  return useQuery(["menu-info", id], () => getMenuInfo(id), {
    ...options,
  });
};
