import { useMemo } from "react";
// import type { TagProps } from "@blueprintjs/core";
import {
  MultiSelect2,
  MultiSelect2Props,
  ItemPredicate,
} from "@blueprintjs/select";
import { MenuItem2 } from "@blueprintjs/popover2";

export interface OptionType {
  label: string;
  value: string;
}
const SelectComponent = MultiSelect2.ofType<OptionType>();

export interface MultiSelectProps
  extends Pick<
    MultiSelect2Props<OptionType>,
    "className" | "disabled" | "fill" | "placeholder" | "itemDisabled" | "items"
  > {
  value: string[];
  onChange: (value: string[]) => void;
  // tagProps?: TagProps;
}

const MultiSelect = ({
  items,
  value,
  onChange,
  // tagProps,
  ...selectProps
}: MultiSelectProps) => {
  const getSelectedOptionIndex = (optionValue: string) => {
    return value.indexOf(optionValue);
  };
  const isOptionSelected = (optionValue: string) => {
    return getSelectedOptionIndex(optionValue) !== -1;
  };

  const handleItemSelect = (option: OptionType) => {
    if (!isOptionSelected(option.value)) selectOption(option.value);
    else deselectOption(getSelectedOptionIndex(option.value));
  };
  const handleItemsPaste = (options: OptionType[]) => {
    selectOption(options.map(({ value }) => value));
  };
  const selectOption = (option: string | string[]) => {
    onChange?.(value.concat(option));
  };

  const deselectOption = (index: number) => {
    onChange?.(value.filter((_, i) => i !== index));
  };

  const selectValues = useMemo(() => {
    const itemMap = items.reduce((prev, item) => {
      prev.set(item.value, item);
      return prev;
    }, new Map<string, OptionType>());

    return value.reduce((prev, item) => {
      const optionItem = itemMap.get(item);
      if (optionItem) prev.push(optionItem);
      return prev;
    }, [] as OptionType[]);
  }, [value, items]);
  return (
    <SelectComponent
      items={items}
      itemsEqual={(a, b) => a.label.toLowerCase() === b.label.toLowerCase()}
      itemPredicate={itemPredicate}
      menuProps={{ "aria-label": "select" }}
      itemRenderer={(
        option,
        { handleClick, handleFocus, modifiers, query }
      ) => {
        return (
          <MenuItem2
            active={modifiers.active}
            disabled={modifiers.disabled}
            key={option.value}
            label={option.label}
            onClick={handleClick}
            onFocus={handleFocus}
            roleStructure="listoption"
            text={highlightText(option.label, query)}
            selected={isOptionSelected(option.value)}
          />
        );
      }}
      noResults={
        <MenuItem2
          disabled={true}
          text="未匹配到结果"
          roleStructure="listoption"
        />
      }
      popoverProps={{ matchTargetWidth: true, minimal: true }}
      onItemSelect={handleItemSelect}
      onItemsPaste={handleItemsPaste}
      tagRenderer={(option) => option.label}
      tagInputProps={{
        onRemove: (_, i) => deselectOption(i),
        tagProps: { minimal: true },
      }}
      selectedItems={selectValues}
      placeholder="请选择"
      {...selectProps}
    />
  );
};

export default MultiSelect;

const itemPredicate: ItemPredicate<OptionType> = (
  query,
  option,
  _index,
  exactMatch
) => {
  const normalizedTitle = option.label.toLowerCase();
  const normalizedQuery = query.toLowerCase();

  if (exactMatch) return normalizedTitle === normalizedQuery;
  return `${option.value} ${normalizedTitle}`.includes(query);
};

function highlightText(text: string, query: string) {
  let lastIndex = 0;
  const words = query
    .split(/\s+/)
    .filter((word) => word.length > 0)
    .map(escapeRegExpChars);
  if (words.length === 0) {
    return [text];
  }
  const regexp = new RegExp(words.join("|"), "gi");
  const tokens: React.ReactNode[] = [];
  while (true) {
    const match = regexp.exec(text);
    if (!match) {
      break;
    }
    const length = match[0].length;
    const before = text.slice(lastIndex, regexp.lastIndex - length);
    if (before.length > 0) {
      tokens.push(before);
    }
    lastIndex = regexp.lastIndex;
    tokens.push(<strong key={lastIndex}>{match[0]}</strong>);
  }
  const rest = text.slice(lastIndex);
  if (rest.length > 0) {
    tokens.push(rest);
  }
  return tokens;
}

function escapeRegExpChars(text: string) {
  return text.replace(/([.*+?^=!:${}()|[\]/\\])/g, "\\$1");
}
