import { useMemo } from "react";
import type {
  DataHookOptions,
  WhereConditionInterface,
} from "@/shared/types/data";
import { buildWhereConditionParams } from "@/shared/utils/request";

export const useWhereConditionParam = (
  options: Required<DataHookOptions>,
  sqlAbbr: string
) => {
  return useMemo(() => {
    const { pagination, params, whereConfig, orderBy, sqlNameMap } = options;
    const { pageIndex = 0, pageSize } = pagination;
    const queryParams: WhereConditionInterface = {
      page: { current: pageIndex! + 1, size: pageSize! },
      where: buildWhereConditionParams({
        params,
        sqlAbbr,
        whereConfig,
        orderBy,
        sqlNameMap,
      }),
    };
    return queryParams;
  }, [options, sqlAbbr]);
};

export const createWhereConditionParam = (
  options: Required<DataHookOptions>,
  sqlAbbr: string
): WhereConditionInterface => {
  const { pagination, params, whereConfig, orderBy } = options;
  const { pageIndex = 0, pageSize } = pagination;
  const queryParams: WhereConditionInterface = {
    page: { current: pageIndex! + 1, size: pageSize! },
    where: buildWhereConditionParams({
      params,
      sqlAbbr,
      whereConfig,
      orderBy,
    }),
  };
  return queryParams;
};
