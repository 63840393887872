import { useState } from "react";
import { PaginationState } from "@/shared/components/BasicTable";

import { defaultPageSize } from "@/config";
export const usePagination = (pageSize?: number) => {
  return useState<PaginationState>({
    pageIndex: 0,
    pageSize: pageSize ?? defaultPageSize,
  });
};
