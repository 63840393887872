import classNames from "classnames";
import { forwardRef } from "react";
import {
  FormGroup,
  NumericInput,
  FormGroupProps,
  NumericInputProps,
} from "@blueprintjs/core";

export interface InputNumberProps
  extends Omit<FormGroupProps, "children" | "onChange">,
    Omit<
      NumericInputProps,
      "disabled" | "className" | "ref" | "onValueChange"
    > {
  inputGroupClassName?: string;
  onChange?: NumericInputProps["onValueChange"];
}

export const InputNumber = forwardRef<HTMLInputElement, InputNumberProps>(
  (
    {
      className,
      contentClassName,
      inputGroupClassName,
      helperText,
      inline,
      intent,
      label,
      labelFor,
      labelInfo,
      style,
      subLabel,
      onChange,
      ...props
    },
    ref
  ) => {
    return (
      <FormGroup
        className={classNames("!mb-0", className)}
        {...{
          contentClassName,
          helperText,
          inline,
          intent,
          label,
          labelFor,
          labelInfo,
          style,
          subLabel,
        }}
      >
        <NumericInput
          className={inputGroupClassName}
          {...props}
          inputRef={ref}
          onValueChange={onChange}
        />
      </FormGroup>
    );
  }
);

InputNumber.displayName = "InputNumber";
