import { Alert as AlertComponents } from "@blueprintjs/core";
import { useState } from "react";
import { useAlert } from ".";

interface IAlertProps {
  usePortal?: boolean;
}
export const Alert = (props: IAlertProps) => {
  const {
    visible,
    setVisible,
    icon,
    content,
    confirmText,
    cancelText,
    intent,
    onConfirm,
    onCancel,
  } = useAlert();

  const [loading, setLoading] = useState(false);

  const handleConfirm = async () => {
    try {
      setLoading(true);
      await onConfirm();
      setVisible(false);
    } catch (e) {
      throw e;
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = async () => {
    setVisible(false);
    onCancel();
  };
  return (
    <AlertComponents
      {...props}
      portalContainer={document.getElementById("alert") as HTMLElement}
      icon={icon}
      intent={intent}
      isOpen={visible}
      onCancel={handleCancel}
      onConfirm={handleConfirm}
      cancelButtonText={cancelText}
      confirmButtonText={confirmText}
      loading={loading}
    >
      {content}
    </AlertComponents>
  );
};

Alert.defaultProps = {
  usePortal: true,
};
