import { useState, useMemo } from "react";
import { useFormContext, Controller } from "@/shared/libs/react-hook-form";
import { InputGroup, InputGroupProps, Button } from "@blueprintjs/core";
import { FieldWrapper, FieldWrapperPassThroughProps } from "../../index";

export type InputPasswordProps = FieldWrapperPassThroughProps &
  InputGroupProps & {
    field: string;
    required?: boolean;
    defaultValue?: string | number;
  };

export const InputPassword = ({
  className,
  field,
  required,
  defaultValue,
  label,
  error,
  inline,
  labelNum,
  ...inputProps
}: InputPasswordProps) => {
  const methods = useFormContext();
  const [lock, setLock] = useState(true);

  const lockBtn = useMemo(() => {
    return (
      <Button
        icon={lock ? "eye-off" : "eye-open"}
        minimal
        intent="primary"
        onClick={() => setLock(!lock)}
      />
    );
  }, [lock]);

  return (
    <Controller
      control={methods?.control}
      defaultValue={defaultValue ?? ""}
      name={field}
      render={(props) => {
        return (
          <FieldWrapper
            label={label}
            inline={inline}
            labelNum={labelNum}
            error={error ?? methods?.formState.errors[field]}
            required={required}
          >
            <InputGroup
              type={lock ? "password" : "text"}
              autoComplete={"off"}
              className={className}
              rightElement={lockBtn}
              {...inputProps}
              {...props.field}
            />
          </FieldWrapper>
        );
      }}
    />
  );
};
