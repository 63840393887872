import { useLocation } from "react-router-dom";
import { useDictStore } from "@/shared/models/dict";
import { useState, useEffect, useMemo, Suspense } from "react";
import { useAuth } from "@/shared/libs/auth";
import { useNavigation } from "@/shared/libs/navigation";
import { useKeepAlive } from "@/shared/libs/keepAlive";
import { useTitle } from "@/shared/hooks";

import { Alert } from "@/features/Alert";
import { Header, Content, PasswordChange } from "./components";
import { ERPRouter } from "./routes";

export const ERPLayout = () => {
  const location = useLocation();
  const initlize = useDictStore((state) => state.initlize);
  const { user, logout } = useAuth();
  const { navList } = useNavigation();
  const { push } = useKeepAlive();
  const setTitle = useTitle();
  const [showPwdChange, setShowPwdChange] = useState(false);

  useEffect(() => {
    initlize();
  }, [initlize]);

  // 获取当前正在访问的顶层路由信息
  // 用于传递 children 的值给 Navbar 组件，以及变更标题名称
  const currentTopNav = useMemo(() => {
    const path = location.pathname.split("/")[2];
    return navList?.find((nav) => nav.path === path);
  }, [navList, location]);

  useEffect(() => {
    const paths = location.pathname.split("/").slice(2);

    const current = paths
      .slice(1)
      .reduce<ArrayExtract<typeof navList> | undefined>(
        (prev, path) => {
          return prev?.children.find((i) => i.path === path);
        },
        navList?.find((item) => item.path === paths[0])
      );

    if (current) {
      push({
        active: true,
        description: current.remark,
        name: current.name,
        key: current.path,
        title: current.name,
      });
      setTitle(current.name);
    }
  }, [location.pathname, navList, push, setTitle]);

  useEffect(() => {
    const listener = (event: KeyboardEvent) => {
      if (event.key === "Enter") {
        const target = event.target as HTMLElement;
        /** 判断回车事件是否是由 INPUT 发起的，如果是，则阻止默认事件的发生 */
        if (target.nodeName === "INPUT") {
          event.preventDefault();
        }
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, []);

  return (
    <div className="h-[100vh] min-w-[1280px] w-full flex flex-col">
      <Header
        user={user!}
        navList={navList ?? []}
        handleChangePassword={setShowPwdChange.bind(undefined, true)}
        hanldeLogOut={logout}
      />
      <Content currentNav={currentTopNav}>
        <Suspense>
          <ERPRouter navList={navList ?? []} />
        </Suspense>
      </Content>
      <PasswordChange
        isOpen={showPwdChange}
        onClose={() => setShowPwdChange(false)}
        onSuccess={() => {
          setShowPwdChange(false);
          logout();
        }}
      />
      <Alert />
    </div>
  );
};
