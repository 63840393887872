import request from "@/shared/libs/axios";
import type { NavigationType } from ".";
import { deepSort } from "@/shared/utils/array";

export const getNavigate = async () => {
  return request.post<NavigationType[]>("/sys/menu/nav").then((navList) => {
    return deepSort(navList, "children", (a, b) => {
      return b.orderNum - a.orderNum;
    });
  });
};
