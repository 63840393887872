import create from "zustand";
import { IntentProps, IconName } from "@blueprintjs/core";

interface AlertOptions extends IntentProps {
  content?: React.ReactNode;
  icon?: IconName;
  confirmText?: string;
  cancelText?: string;
  onConfirm?: () => Promise<void> | void;
  onCancel?: () => Promise<void> | void;
}
interface AlertModel extends Required<AlertOptions> {
  visible: boolean;
  setVisible: (visible?: boolean) => void;
  confirm: (options: AlertOptions) => void;
}
export const useAlert = create<AlertModel>((set) => {
  return {
    visible: false,
    content: "",
    icon: "confirm",
    confirmText: "确定",
    cancelText: "取消",
    intent: "none",
    onConfirm: () => Promise.resolve(),
    onCancel: () => Promise.resolve(),
    setVisible: (visible) => {
      set((state) => ({
        ...state,
        visible: visible ?? !state.visible,
      }));
    },
    confirm: (options) => {
      set((state) => ({ ...state, ...options, visible: true }));
    },
  };
});
