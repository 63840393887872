import { isNil } from "lodash-es";
export const deepSort = <T = any>(
  list: T[],
  childrenKey: string,
  fn: (a: T, b: T) => number
): T[] => {
  const newList = Array.from(list);
  newList.sort(fn);
  newList.forEach((value: any) => {
    const child = value[childrenKey] as T[];
    if (child) value[childrenKey] = deepSort(child, childrenKey, fn);
  });
  return newList;
};

export const arrayToTree = <
  T extends Record<string, any> = Record<string, any>
>(
  list: T[],
  key: string,
  parentKey: string,
  rootValue?: string | number
): any => {
  const reduceHandle = (prev: Record<any, any>, next: T) => {
    // 获取当前项的 parentKey 值
    const parentKeyValue = next[parentKey] || (rootValue ?? "root");
    // 检查缓存中是否存在对应 parent 的项，
    if (isNil(prev[parentKeyValue])) {
      prev[parentKeyValue] = {
        childrenHash: {},
      };
    } else {
      if (isNil(prev[parentKeyValue].childrenHash)) {
        prev[parentKeyValue].childrenHash = {};
      }
    }
    const parentValue = prev[parentKeyValue];

    parentValue.childrenHash = Object.assign(parentValue.childrenHash, {
      [next[key]]: next,
    });

    prev[next[key]] = Object.assign(next, prev[next[key]]);
    return prev;
  };

  const treeList = Array.from(list).reduce(reduceHandle, {})[
    rootValue ?? "root"
  ];

  const generatorTreeList = (item: any): any[] => {
    const { childrenHash } = item;
    if (!childrenHash) return [];
    const childrenList = Object.values(childrenHash).map((children: any) => {
      const childrenList = generatorTreeList(children);
      return { ...children, children: childrenList, childrenHash: undefined };
    });

    return childrenList;
  };
  return generatorTreeList(treeList);
};

export type TreeFlatItem<T = Record<string, any>> = T & { pid: string };
export const treeFlat = <T extends Record<string, any> = any>(
  list: T[],
  idKey: string,
  childrenKey: string
): TreeFlatItem<T>[] => {
  const result: TreeFlatItem<T>[] = [];

  const flatFn = (item: T, parentId: string) => {
    const childList: T[] = item[childrenKey];
    result.push({ ...item, pid: parentId });
    childList?.forEach?.((citem) => flatFn(citem, item[idKey]));
  };

  list.forEach((item) => flatFn(item, ""));
  return result;
};
