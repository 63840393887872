/**
 * 国内省市区三级联动数据
 * 数据来源于：https://github.com/modood/Administrative-divisions-of-China
 */
import LocationPCA from "@/shared/utils/location-pca.json";
import { useCallback, useMemo } from "react";
import { isNil } from "lodash-es";

const LocationPCAJSON: Record<
  string,
  Record<string, Record<string, string[]>>
> = LocationPCA;
const getCityInfo = (key: string) => {
  const cityObject = (
    LocationPCAJSON as Record<string, Record<string, Record<string, string[]>>>
  )[key];

  return cityObject;
};
export const useLocation = () => {
  const provinceList = useMemo(() => {
    return Object.keys(LocationPCAJSON);
  }, []);

  const getCitys = useCallback((key: string) => {
    const cityObject = getCityInfo(key);
    return Object.keys(cityObject ?? {});
  }, []);

  const getCounty = useCallback((province: string, key: string) => {
    const cityObject = getCityInfo(province);
    const countyList = cityObject?.[key];

    return Object.keys(countyList ?? {});
  }, []);

  const getTown = useCallback((province: string, city: string, key: string) => {
    const cityObject = getCityInfo(province);
    const countyList = cityObject?.[city] ?? {};
    const townList = countyList?.[key] ?? [];

    return townList;
  }, []);

  const validLocation = useCallback(
    (province: string, city?: string, county?: string, town?: string) => {
      const provinceValid = !!LocationPCAJSON[province];
      if (isNil(city)) return provinceValid;
      const cityValid = !!LocationPCAJSON[province]?.[city];
      if (isNil(county)) return cityValid;
      const countyValid = !!LocationPCAJSON[province]?.[city]?.[county];
      if (isNil(town)) return countyValid;
      const townValid =
        !!LocationPCAJSON[province]?.[city]?.[county]?.includes(town);
      return townValid;
    },
    []
  );

  return { provinceList, getCitys, getCounty, getTown, validLocation };
};
