import { useFormContext, Controller } from "@/shared/libs/react-hook-form";
import { FieldWrapper, FieldWrapperPassThroughProps } from "../../index";
import IconSelectComponent, {
  IconSelectProps as IconSelectComponentProps,
} from "@/shared/components/IconSelect";

export type IconSelectProps = FieldWrapperPassThroughProps &
  IconSelectComponentProps & {
    field: string;
    required?: boolean;
    defaultValue?: string | number;
  };

export const IconSelect = ({
  className,
  field,
  required,
  defaultValue,
  label,
  error,
  inline,
  labelNum,
  ...iconSelectProps
}: IconSelectProps) => {
  const methods = useFormContext();

  return (
    <Controller
      control={methods?.control}
      defaultValue={defaultValue ?? ""}
      name={field}
      render={(props) => {
        return (
          <FieldWrapper
            className={className}
            label={label}
            labelNum={labelNum}
            inline={inline}
            error={error ?? methods?.formState.errors[field]}
            required={required}
          >
            <IconSelectComponent
              {...iconSelectProps}
              value={props.field.value}
              onChange={props.field.onChange}
            />
          </FieldWrapper>
        );
      }}
    />
  );
};
