import { useNavigate } from "react-router-dom";
import { NonIdealState, Button } from "@blueprintjs/core";
import { useAuth } from "@/shared/libs/auth";

const PageNotFound = () => {
  const { logout } = useAuth();
  const navigate = useNavigate();
  return (
    <div className="h-[100vh] w-[100vw] flex items-center justify-center bg-gray-100">
      <div className="p-12 bg-white rounded-lg shadow-md shadow-gray-200">
        <NonIdealState
          icon="issue"
          title="页面不存在"
          description="未查询到当前页面，请联系管理人员"
          action={
            <div className="flex gap-2">
              <Button intent="primary" onClick={() => navigate(-1)}>
                返回上一页面
              </Button>
              <Button onClick={() => logout()}>重新登录</Button>
            </div>
          }
        />
      </div>
    </div>
  );
};

export default PageNotFound;
