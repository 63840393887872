import styled from "styled-components";

export const FormStyled = styled.main`
  display: flex;
  align-items: center;
  justify-content: center;

  > div {
    width: 400px;
    padding: 24px;
    border-radius: 8px;
    background-color: #fff;
    border: solid 1px rgba(234, 234, 234, 0.3);
  }
`;

export const LayoutStyled = styled.div`
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background-color: #eeeeee;
  &.bp4-dark {
    background-color: #2f343c;

    ${FormStyled} {
      > div {
        background-color: #30343b;
      }
    }
  }

  > * {
    flex: 1;
  }
`;
