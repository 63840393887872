import { useFormContext, Controller } from "@/shared/libs/react-hook-form";
import {
  Checkbox as CheckboxComponent,
  CheckboxProps as CheckboxComponentProps,
} from "@blueprintjs/core";
import { FieldWrapper, FieldWrapperPassThroughProps } from "../../index";

export type CheckboxProps = FieldWrapperPassThroughProps &
  CheckboxComponentProps & {
    field: string;
    required?: boolean;
    defaultValue?: boolean;
  };

export const Checkbox = ({
  className,
  field,
  required,
  defaultValue,
  label,
  labelNum,
  error,
  inline,
  ...inputProps
}: CheckboxProps) => {
  const methods = useFormContext();

  return (
    <Controller
      control={methods?.control}
      defaultValue={defaultValue ?? false}
      name={field}
      render={(props) => {
        return (
          <FieldWrapper
            labelNum={labelNum}
            label={label}
            inline={inline}
            required={required}
            error={error ?? methods?.formState.errors[field]}
          >
            <CheckboxComponent
              className={className}
              checked={props.field.value}
              {...inputProps}
              {...props.field}
            />
          </FieldWrapper>
        );
      }}
    />
  );
};
