import { PropsWithChildren, CSSProperties, useState, useMemo } from "react";
import { NavigationType } from "@/shared/api/system/menu";

import { sizes } from "@/shared/styles";
import { SiderBar } from "./SiderBar";

interface IContentProps {
  currentNav?: NavigationType;
}
export const Content = ({
  currentNav,
  children,
}: PropsWithChildren<IContentProps>) => {
  const [collapsed, setCollapsed] = useState(false);

  const contentStyle = useMemo(() => {
    const style: CSSProperties = {};
    if (!currentNav?.children.length) return style;
    return {
      paddingLeft: collapsed
        ? sizes.appNavBarCollaspedWidth
        : sizes.appNavBarWidth,
    };
  }, [collapsed, currentNav]);

  return (
    <div className="flex-1 flex min-h-0 relative">
      {!!currentNav?.children.length && (
        <SiderBar
          currentNav={currentNav}
          collapsed={collapsed}
          onCollapse={setCollapsed.bind(this, !collapsed)}
        />
      )}
      <div className="flex-1 min-w-0" style={contentStyle}>
        {children}
      </div>
    </div>
  );
};
