import dayjs from "dayjs";
import type { PaginationState } from "@tanstack/react-table";
// WhereContionKey 以及对应键的释意
export enum whereConditionKey {
  eq = "等于",
  ne = "不等于",
  lt = "小于",
  le = "小于等于",
  gt = "大于",
  ge = "大于等于",
  in = "在...里，使用逗号分隔",
  nin = "不在...里，使用逗号分隔",
  lk = "模糊查询",
  nlk = "不模糊查询",
  rlk = "右模糊",
  llk = "左模糊",
  null = "为 null",
  nnull = "非 null",
  asc = "生序排序",
  desc = "降序排序",
}
export type WhereConditionKey = keyof typeof whereConditionKey;

export interface BuildQueryParamsOption<T = Record<string, any>> {
  pagination?: PaginationState;
  params?: Partial<Record<keyof T, any>> & Record<string, any>;
  whereType?: Partial<Record<keyof T, WhereConditionKey>> &
    Record<string, WhereConditionKey>;
  orderBy?: Partial<Record<keyof T, "asc" | "desc">>;
  paramsAbbr?: Partial<Record<keyof T, string>> & Record<string, string>;
}
export const buildQueryParams = (
  {
    pagination = { pageIndex: 0, pageSize: 20 },
    params = {},
    whereType = {},
    orderBy = {},
    paramsAbbr = {},
  }: BuildQueryParamsOption,
  dbAbbr: string
) => {
  const queryParam: Required<QueryParams> = {
    page: {
      current: pagination.pageIndex + 1,
      size: pagination.pageSize,
    },
    where: Object.entries(params).reduce((prev, [key, value]) => {
      const abbr = paramsAbbr[key] ?? dbAbbr;
      if (Array.isArray(value)) {
        const [start, end] = value;
        prev[`ge_${abbr}.${key}`] =
          start instanceof Date
            ? dayjs(start).startOf("day").format("YYYY-MM-DD HH:mm:ss")
            : start;
        prev[`le_${abbr}.${key}`] =
          end instanceof Date
            ? dayjs(end).endOf("day").format("YYYY-MM-DD HH:mm:ss")
            : end;
      } else {
        const type = whereType[key] ?? "lk";
        prev[`${type}_${abbr}.${key}`] = value;
      }

      return prev;
    }, {} as Record<string, any>),
  };

  Object.entries(orderBy).forEach(([key, type]) => {
    queryParam.where[`${type}_${dbAbbr}.${key}`] = 1;
  });

  // 如果未传入此参数，则将其默认设置为 1
  if (!params.recordStatus) queryParam.where[`eq_${dbAbbr}.recordStatus`] = 1;
  return queryParam;
};
