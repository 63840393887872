import dayjs from "dayjs";
import { isNil } from "lodash-es";

export type DateType = number | string | Date | dayjs.Dayjs;

export const formatDateTime = (date: DateType) => {
  dayjs(date).format("YYYY-MM-DD HH:mm:ss");
};

export const formatDate = (date: DateType) => {
  dayjs(date).format("YYYY-MM-DD");
};
export const formatTime = (date: DateType) => {
  dayjs(date).format("HH:mm:ss");
};

export const clearNullValue = <T extends any = any>(
  values: T,
  deep?: boolean
): T => {
  const deepClear = deep ?? true;
  if (typeof values !== "object") return values;
  const newValues = Object.entries(values as Record<string, any>).reduce(
    (prev, [key, value]) => {
      if (!isNil(value)) {
        if (deepClear) {
          if (Array.isArray(value)) {
            prev[key] = value.map((value) => clearNullValue(value, deep));
          } else if (typeof value === "object" && deep) {
            prev[key] = clearNullValue(value, deep);
          } else {
            prev[key] = value;
          }
        } else {
          prev[key] = value;
        }
      }
      return prev;
    },
    {} as Record<string, any>
  );

  return newValues as T;
};
