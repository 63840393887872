import { useMemo, useState } from "react";
import { useLocation } from "react-router-dom";

export const useCurrentLocation = () => {
  const location = useLocation();
  const [currentLocation] = useState(() => location);

  const isCurrent = useMemo(() => {
    return currentLocation.pathname === location.pathname;
  }, [currentLocation, location]);
  return { currentLocation, isCurrent, location };
};
