import dayjs from "dayjs";
import classNames from "classnames";

import { FormGroup, FormGroupProps } from "@blueprintjs/core";
import { DateInput2, DateInput2Props } from "@blueprintjs/datetime2";
import { localUtils } from "@/shared/utils/dateUtils";
import "date-fns/locale/zh-CN";
import "dayjs/locale/zh-cn";

export interface DatePickerProps
  extends Omit<FormGroupProps, "children">,
    Omit<DateInput2Props, "formatDate" | "parseDate"> {
  small?: boolean;
  large?: boolean;
  formatDate?: DateInput2Props["formatDate"];
  parseDate?: DateInput2Props["parseDate"];
}
export const DatePicker = ({
  className,
  contentClassName,
  helperText,
  inline,
  intent,
  label,
  labelFor,
  labelInfo,
  style,
  subLabel,
  placeholder = "请选择日期",
  formatDate = (date) => dayjs(date).format("YYYY-MM-DD"),
  parseDate = (string) => dayjs(string).toDate(),
  inputProps,
  small = true,
  large,
  ...dateRangeInput2Props
}: DatePickerProps) => {
  return (
    <FormGroup
      className={classNames("!mb-0", className)}
      {...{
        contentClassName,
        helperText,
        inline,
        intent,
        label,
        labelFor,
        labelInfo,
        style,
        subLabel,
      }}
    >
      <DateInput2
        popoverProps={{ minimal: true, position: "bottom-left" }}
        dayPickerProps={{
          locale: "zh-cn",
          localeUtils: localUtils,
        }}
        formatDate={formatDate}
        parseDate={parseDate}
        placeholder={placeholder}
        minDate={dayjs("1900-01-01").toDate()}
        maxDate={dayjs("2099-12-31").toDate()}
        outOfRangeMessage={"时间超出可选择范围"}
        invalidDateMessage="无效的时间日期"
        {...dateRangeInput2Props}
        inputProps={{
          small,
          large,
          ...inputProps,
        }}
      />
    </FormGroup>
  );
};
