import { ComponentType, lazy, LazyExoticComponent } from "react";

export type Component = ComponentType<any> | LazyExoticComponent<any>;

// require pages 目录下的所有 tsx 文件
// 通过循环遍历所有文件，将文件路径作为 key，页面作为值，返回一个组件 hash 对象
// 这段正则会匹配所有 tsx 文件，需要优化去除掉 components 文件夹下的 files
// 目前正则水平不够，暂且放一放
const files = require.context(
  "../pages/",
  true,
  /^((?!components\/).)*\.tsx$/,
  "lazy"
);

export const componentHash = files.keys().reduce((acc, key) => {
  const name = key.slice(2, -4);
  acc[name] = lazy(() => files(key));
  return acc;
}, {} as Record<any, Component>);
