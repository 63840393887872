import { initQueryAuth } from "@/shared/models/auth";
import type { InitAuthModelOptions } from "@/shared/models/auth";

import { Button, NonIdealState } from "@blueprintjs/core";

import PageLoader from "@/shared/components/PageLoader";

import { login, register, getUser } from "@/shared/api/system/auth";
import {
  findCurLoginUserControlDict,
  findCurrentUserAllowProfit,
  findCurrentUserAllowWarehouse,
} from "@/shared/api/system/menu";
import type {
  UserInfo,
  LoginCredentialsDTO,
  RegisterCredentialsDTO,
} from "@/shared/api/system/auth";

import { token as tokenStorage } from "@/shared/utils/storage";
import type { ResponseType } from "@/shared/types";

import { responseMessageKey, responseCodeKey, invalidCode } from "@/config";

export interface UserInfoType extends UserInfo {
  dict: Awaited<ReturnType<typeof findCurLoginUserControlDict>>;
  profit: Awaited<ReturnType<typeof findCurrentUserAllowProfit>>;
  warehouse: Awaited<ReturnType<typeof findCurrentUserAllowWarehouse>>;
  isCustomerLeader: boolean;
  allowReadPrice: boolean;
  allowSyncSap: boolean;
  allowShowBalanceOptions: boolean;
}

const getUserInfoData = async (): Promise<UserInfoType> => {
  const userInfo = await getUser();
  const dict = await findCurLoginUserControlDict();
  const profit = await findCurrentUserAllowProfit().then((result) =>
    result.sort((a, b) => (a.profitCode > b.profitCode ? 1 : -1))
  );
  const warehouse = await findCurrentUserAllowWarehouse().then((result) =>
    result.sort((a, b) => (a.lgort > b.lgort ? 1 : -1))
  );

  const isCustomerLeader = !!dict
    .find((item) => item.type === "ROLE_CONFIG")
    ?.dictDataList.find((item) => item.code === "CUSTOMER_SERVICE_LEADER");

  const allowReadPrice = !!dict
    .find((item) => item.type === "DATA_SHOW")
    ?.dictDataList.find((item) => item.code === "SHOW_PRICE_FLAG");
  const allowSyncSap = !!dict
    .find((item) => item.type === "DATA_SHOW")
    ?.dictDataList.find((item) => item.code === "SAP_SYNC_FLAG");
  const allowShowBalanceOptions = !!dict
    .find((item) => item.type === "DATA_SHOW")
    ?.dictDataList.find((item) => item.code === "BALANCE_REPORT_OPTION");

  return {
    ...userInfo,
    dict,
    profit,
    warehouse,
    isCustomerLeader,
    allowReadPrice,
    allowSyncSap,
    allowShowBalanceOptions,
  };
};

const saveToken = (token: any) => {
  tokenStorage.setToken(token);
};
// 判断是否存在 token，存在 token 则获取用户信息，反之给定初始值 null
const loadUser = async () => {
  return tokenStorage.getToken() ? getUserInfoData() : null;
};
const loginFn = async (data: LoginCredentialsDTO) => {
  const result = await login(data);
  saveToken(result);
  const user = getUserInfoData();
  return user;
};

const registerFn = async (data: RegisterCredentialsDTO) => {
  const result = await register(data);
  saveToken(result);
  const user = getUserInfoData();
  return user;
};

const logoutFn = async () => {
  tokenStorage.clearToken();
  window.location.assign(window.location.origin);
};

const option: InitAuthModelOptions<
  UserInfoType,
  ResponseType,
  LoginCredentialsDTO,
  RegisterCredentialsDTO
> = {
  loginFn,
  registerFn,
  loadUser,
  logoutFn,
  LoaderComponent: () => <PageLoader />,
  ErrorComponent: ({ error }) => {
    if (error && invalidCode.includes(error[responseCodeKey])) {
      logoutFn();
    }
    return (
      <NonIdealState
        className="p-52"
        icon={"error"}
        iconSize={48}
        title={error ? `错误编码：${error[responseCodeKey]}` : "未知错误"}
        description={error?.[responseMessageKey]}
        action={
          <Button className="mt-2" intent={"primary"} onClick={logoutFn}>
            返回首页
          </Button>
        }
      />
    );
  },
};

export const { AuthConsumer, AuthProvider, useAuth } = initQueryAuth(option);
