import type { ParamsType, WhereConfig, OrderByType } from "@/shared/types/data";
import { clearNullValue } from "@/shared/utils/format";

interface IBuildWhereConditionParamsOptions<T> {
  /** 参数值 */
  params: T;
  /** 数据库简写 */
  sqlAbbr: string;
  /** 参数对应的匹配类型，不传或不存在对应键则全为 lk */
  whereConfig?: WhereConfig;
  /** orderBy 排序参数 */
  orderBy?: OrderByType;
  /** 子表查询表名称参数 */
  sqlNameMap?: Record<string, any>;
}

/** 构建后端接口需要使用的 whereCondition 参数 */
export const buildWhereConditionParams = <T extends ParamsType = ParamsType>({
  params,
  sqlAbbr,
  whereConfig,
  orderBy = {},
  sqlNameMap = {},
}: IBuildWhereConditionParamsOptions<T>) => {
  // 构建 where 参数
  const whereParam = Object.entries(params).reduce((prev, [key, value]) => {
    const whereType = whereConfig?.[key] ?? "lk";
    if (Array.isArray(value)) {
      prev[`${["ge", sqlNameMap[key] ?? sqlAbbr].join("_")}.${key}`] = value[0];
      prev[`${["le", sqlNameMap[key] ?? sqlAbbr].join("_")}.${key}`] = value[1];
    } else {
      prev[`${[whereType, sqlNameMap[key] ?? sqlAbbr].join("_")}.${key}`] =
        value;
    }
    return prev;
  }, {} as Record<string, any>);
  whereParam[`eq_${sqlAbbr}.recordStatus`] = 1;
  // 构建 orderBy 参数
  const orderByParam = Object.entries(orderBy).reduce((prev, [key, value]) => {
    prev[`${[value, sqlAbbr].join("_")}.${key}`] = "1";
    return prev;
  }, {} as Record<string, any>);

  // 组合 where 与 orderBy，并且去除值为 null 和 undefined 的项
  return clearNullValue(Object.assign(whereParam, orderByParam));
};
