/**
 * 校验邮箱格式使用的正则。
 * allowEmpty 为 true 的状态下，则允许空字符串通过校验
 */
export const isMail = (allowEmpty?: boolean) => {
  if (allowEmpty) return /(^$)|(^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[a-zA-Z0-9]+$)/;
  return /(^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[a-zA-Z0-9]+$)/;
};

export const withSpace = /^\S+$/;

interface IOnlyLetterOptions {
  allowUpper?: boolean;
  allowLower?: boolean;
  withUnderline?: boolean;
  withNumber?: boolean;
}
export const onlyLetter = ({
  allowLower,
  allowUpper,
  withNumber,
  withUnderline,
}: IOnlyLetterOptions) => {
  return new RegExp(
    [
      "^[",
      allowUpper ? "A-Z" : "",
      allowLower ? "a-z" : "",
      withNumber ? "0-9" : "",
      withUnderline ? "_" : "",
      "]",
    ].join("")
  );
};

export const isPhone = (value: string, allowEmpty?: boolean) => {
  if (allowEmpty && !value) return true;
  return /^1[3-9]\d{9}$/.test(value);
};

export const isIdNo = (value: string, allowEmpty?: boolean) => {
  if (allowEmpty && !value) return true;
  const p =
    /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
  const factor = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2];
  const parity = [1, 0, "X", 9, 8, 7, 6, 5, 4, 3, 2];
  const code = value.substring(17);

  if (p.test(value)) {
    return true;
  }

  return false;
};
