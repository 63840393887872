import { createContext, useContext } from "react";
import { useToast } from "@/shared/hooks";
import { FormProvider } from "@/shared/libs/react-hook-form";
import type {
  SubmitHandler,
  SubmitErrorHandler,
  UseFormReturn,
  FieldValues,
} from "react-hook-form";
import { FormContainer } from "./Styles";

export interface FormConfigContext {
  inline?: boolean;
  labelNum?: number;
}

export type FormProps<T extends FieldValues> = {
  className?: string;
  onSubmit: SubmitHandler<T>;
  onError?: SubmitErrorHandler<T>;
  onReset?: () => void;
  children: React.ReactNode;
  id?: string;
  methods: UseFormReturn<T>;
} & FormConfigContext;

const FormConfig = createContext<FormConfigContext | null>(null);

export const Form = <FormValues extends FieldValues = FieldValues>({
  onSubmit,
  onError,
  children,
  methods,
  inline = false,
  labelNum = 4,
  ...props
}: FormProps<FormValues>) => {
  const { fail } = useToast();

  const defaultErrorHandler: SubmitErrorHandler<FormValues> = (e) => {
    console.error(e);
    const [first] = Object.keys(e);
    const value = e[first];
    if (Array.isArray(value) && value.length) {
      const [valueFirst] = value.filter((item) => !!item);
      if (!valueFirst) return;
      if (valueFirst?.message) {
        fail(valueFirst?.message as string);
      } else {
        const [valueFirstKey] = Object.keys(valueFirst);
        fail(valueFirst[valueFirstKey]?.message as string);
      }
    } else {
      fail(value?.message as string);
    }
  };
  
  return (
    <FormProvider {...methods}>
      <FormContainer
        autoComplete="off"
        {...props}
        onSubmit={(event) => {
          event.stopPropagation();
          methods.handleSubmit(onSubmit, onError ?? defaultErrorHandler)(event);
        }}
      >
        <FormConfig.Provider
          value={{
            inline,
            labelNum,
          }}
        >
          {children}
        </FormConfig.Provider>
      </FormContainer>
    </FormProvider>
  );
};

// 暴露给函数式组件的 hooks
export const useFormConfig = () => {
  const context = useContext(FormConfig);
  if (!context) {
    throw new Error("useAuth must be used within a AuthProvider");
  }
  return context;
};
