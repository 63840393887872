import classNames from "classnames";
import { forwardRef } from "react";
import {
  FormGroup,
  InputGroup,
  FormGroupProps,
  InputGroupProps2,
} from "@blueprintjs/core";

export interface InputProps
  extends Omit<FormGroupProps, "children" | "onChange">,
    Omit<InputGroupProps2, "disabled" | "className" | "ref"> {
  inputGroupClassName?: string;
}

export const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      className,
      contentClassName,
      inputGroupClassName,
      helperText,
      inline,
      intent,
      label,
      labelFor,
      labelInfo,
      style,
      subLabel,
      ...props
    },
    ref
  ) => {
    return (
      <FormGroup
        className={classNames("!mb-0", className)}
        {...{
          contentClassName,
          helperText,
          inline,
          intent,
          label,
          labelFor,
          labelInfo,
          style,
          subLabel,
        }}
      >
        <InputGroup
          className={inputGroupClassName}
          small
          {...props}
          inputRef={ref}
        />
      </FormGroup>
    );
  }
);

Input.displayName = "Input";
