import { axios } from "@/shared/libs/axios";
import { AxiosRequestConfig } from "axios";

import { merge } from "lodash-es";

import {
  buildQueryParams,
  BuildQueryParamsOption,
} from "@/shared/utils/queryTool";

/** 构造 API 对象的参数类型 */
export interface APIConstructorOptions {
  prefix?: string;
  controller: string;
  dbAbbr?: string;
  apiMap?: Record<string, string>;
}

/**
 * 构建 CRUD 的 API 封装，此 class 接收范型 D -> DataType
 */
class Api<D = any> {
  private prefix: string;
  private controller: string;
  private dbAbbr: string = "";

  private apiMap: Record<string, string> = {
    queryPage: "queryPage",
    list: "list",
    find: "findById",
    create: "insert",
    update: "updateById",
    delete: "deleteById",
    delBatch: "deleteBatchByIds",
    cancel: "cancelDoc",
  };

  /**
   * @param options 构造 API 对象的参数类型
   * @param {string} options.prefix 请求路径的前缀，默认为 sys
   * @param {string} options.controller 请求接口的 controller 控制器，必传字段
   * @param {string} options.dbAbbr queryPage 请求参数主表的缩写，默认为路径首字母集合
   * @param {Object} options.apiMap 请求路径的 map 用于固定几个常用的接口
   */
  constructor({
    prefix = "sys",
    controller,
    dbAbbr,
    apiMap,
  }: APIConstructorOptions) {
    this.prefix = prefix;
    this.controller = controller;
    this.apiMap = merge(this.apiMap, apiMap);
    if (dbAbbr) this.dbAbbr = dbAbbr;
    else {
      const paths = `${prefix}/${controller}`.split("/");
      this.dbAbbr = paths.reduce((abbr, path) => abbr + path[0], "");
    }
  }

  private getUrl = (key: string) => {
    return ["", this.prefix, this.controller, this.apiMap[key] ?? key].join(
      "/"
    );
  };
  /** 公用的 request 函数，需要自定义接口请求时，使用此函数发起请求，不需要另行引入 request */
  request = <T = any>(
    // ...[path, ...args]: Parameters<typeof axios>,
    path: string,
    data?: any,
    method?: "GET" | "POST" | "PUT" | "DELETE"
  ): Promise<T> => {
    const config: AxiosRequestConfig = {
      method: method ?? "GET",
    };
    if (config.method === "GET") config.params = data;
    else config.data = data;

    const url = this.getUrl(path);
    return axios(url, config) as any;
  };
  /** WhereCondition 查询接口，传入参数，构建为 queryPage 所需的参数 */
  queryPage = (data: BuildQueryParamsOption<D>) => {
    const payload = buildQueryParams(data, this.dbAbbr);
    return this.request<ListResult<D>>("queryPage", payload, "POST");
  };
  list = (data?: Record<string, any>) => {
    return this.request<D[]>("list", data, "POST");
  };
  find = async (id: string | number) => {
    const res = await this.request<D>(`${this.apiMap["find"]}/${id}`);
    if (!!res && typeof res === "object") {
      Object.entries(res).reduce((prev, [key, value]) => {
        if (Array.isArray(value)) {
          const newValue = value.sort((a, b) => {
            if (a.lineNo) return a.lineNo > b.lineNo ? 1 : -1;
            else if (a.lineId) return a.lineId > b.lineId ? 1 : -1;
            return 1;
          });
          prev[key] = newValue;
        } else prev[key] = value;
        return prev;
      }, {} as Record<string, any>);
    }
    return res;
  };
  create = (data: Record<string, any>) => {
    return this.request<unknown>("create", data, "POST");
  };
  update = (data: Record<string, any>) => {
    return this.request<unknown>("update", data, "POST");
  };
  delete = (id: string | number) => {
    return this.request<unknown>(`${this.apiMap["delete"]}/${id}`);
  };
  delBatch = (ids: Array<string | number>) => {
    return this.request<unknown>("delBatch", ids, "POST");
  };
  cancel = (id: string | number) => {
    return this.request<unknown>(`${this.apiMap["cancel"]}/${id}`);
  };
}

export default Api;
