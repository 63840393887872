import {
  DateRangeShortcut,
  DatePickerLocaleUtils,
} from "@blueprintjs/datetime";
import dayjs from "dayjs";
import "dayjs/plugin/localeData";

export const shortCuts: DateRangeShortcut[] = [
  {
    label: "今天",
    dateRange: [dayjs().startOf("day").toDate(), dayjs().endOf("day").toDate()],
  },
  {
    label: "昨天",
    dateRange: [
      dayjs().add(-1, "day").startOf("day").toDate(),
      dayjs().add(-1, "day").endOf("day").toDate(),
    ],
  },
  {
    label: "本周",
    dateRange: [
      dayjs().startOf("week").toDate(),
      dayjs().endOf("week").toDate(),
    ],
  },
  {
    label: "本月",
    dateRange: [
      dayjs().startOf("month").toDate(),
      dayjs().endOf("month").toDate(),
    ],
  },
  {
    label: "上月",
    dateRange: [
      dayjs().add(-1, "month").startOf("month").toDate(),
      dayjs().add(-1, "month").endOf("month").toDate(),
    ],
  },
  {
    label: "近 3 个月",
    dateRange: [
      dayjs().add(-3, "month").startOf("month").toDate(),
      dayjs().endOf("month").toDate(),
    ],
  },
  {
    label: "近 6 个月",
    dateRange: [
      dayjs().add(-6, "month").startOf("month").toDate(),
      dayjs().endOf("month").toDate(),
    ],
  },
  {
    label: "今年",
    dateRange: [
      dayjs().startOf("year").toDate(),
      dayjs().endOf("year").toDate(),
    ],
  },
  {
    label: "去年",
    dateRange: [
      dayjs().add(-1, "year").startOf("year").toDate(),
      dayjs().add(-1, "year").endOf("year").toDate(),
    ],
  },
];

export const localUtils: DatePickerLocaleUtils = {
  formatDay: (day) => dayjs(day).format("ddd ll"),
  formatMonthTitle: (month) => dayjs(month).format("MMMM YYYY"),
  formatWeekdayShort: (day) => dayjs().localeData().weekdaysMin()[day],
  formatWeekdayLong: (day) => dayjs().localeData().weekdays()[day],
  getFirstDayOfWeek: () => dayjs().localeData().firstDayOfWeek(),
  getMonths: () => dayjs().localeData().months(),
  formatDate: (date, format: "L") =>
    dayjs(date).format(Array.isArray(format) ? format[0] : format),
  parseDate: (str, format: "L") => dayjs(str, format).toDate(),
};
