import { useQuery } from "@tanstack/react-query";

import type {
  DataHookOptions,
  WhereConditionInterface,
} from "@/shared/types/data";
import { defaultPageSize } from "@/config";

import { useWhereConditionParam } from "@/shared/hooks";

type OptionsType = {
  abbr: string;
  cacheKey: string;
} & DataHookOptions;

export const useDataQuery = <T = unknown>(
  requestFn: (param: WhereConditionInterface) => Promise<T>,
  {
    abbr,
    cacheKey,
    pagination = { pageIndex: 0, pageSize: defaultPageSize },
    params = {},
    whereConfig = {},
    sqlNameMap = {},
    orderBy = { create_time: "desc" },
  }: OptionsType
) => {
  const queryParams = useWhereConditionParam(
    {
      pagination,
      params,
      whereConfig,
      orderBy,
      sqlNameMap,
    },
    abbr
  );

  return useQuery([cacheKey, queryParams], () => requestFn(queryParams), {
    keepPreviousData: true,
  });
};
