import type { PropsWithChildren } from "react";
import { useState, useCallback, useMemo } from "react";
import { createContext, useContext } from "react";

export interface KeepAliveDTO {
  key: string;
  title: string;
  name: string;
  description: string;
  active: boolean;
  alwaysShow?: boolean;
  noCache?: boolean;
  weight?: number;
}

interface KeepAliveContext {
  current?: KeepAliveDTO;
  keepAliveList: KeepAliveDTO[];
  push: (item: KeepAliveDTO | KeepAliveDTO[]) => void;
  remove: (key: string) => void;
  nextPath: (key: string) => string | null;
  setName: (key: string, name: string) => void;
  clear: () => void;
}
const initKeepAlive = () => {
  const KeepAliveContext = createContext<KeepAliveContext | null>(null);
  const { Consumer, Provider } = KeepAliveContext;

  const KeepAliveProvider = ({ children }: PropsWithChildren) => {
    const [state, setState] = useState<KeepAliveDTO[]>([]);
    // 插入一条新的数据到 state 中
    const push = useCallback((item: KeepAliveDTO | KeepAliveDTO[]) => {
      if (Array.isArray(item)) {
        setState((state) => {
          const keyHash = state.reduce((prev, item) => {
            prev.set(item.key, item);
            return prev;
          }, new Map<string, KeepAliveDTO>());

          return state.concat(
            item
              .filter((item) => !keyHash.has(item.key))
              .map((item) => ({ ...item, active: false }))
          );
        });
        return;
      }
      const { active, key } = item;
      setState((state) => {
        const currentItem = state.find((item) => item.key === key); // 查询已有 state 是否存在相同 key 的 item 值
        // 如果已经存在值，则执行以下逻辑
        if (currentItem) {
          // 如果传入的 active 为 false 或者 currentItem 的 active 为 true，则不做任何操作
          if (!active || currentItem.active) return state;
          // 反之，将当前 current 修改为 true，其他为 false
          return Array.from(state).map((item) => {
            if (item.active && item.key !== key) item.active = false;
            if (item.key === key) item.active = true;
            return item;
          });
        }
        // 如果传入的 active，则将其他项的 active 值设置为 false
        if (active) {
          state.forEach((item) => (item.active = false));
        }
        return state.concat(item);
      });
    }, []);
    // 从 state 中移除指定 key 值的项
    const remove = useCallback((key: string) => {
      setState((state) => state.filter((value) => value.key !== key));
    }, []);
    // 清空当前的 state 数组
    const clear = useCallback(() => {
      setState([]);
    }, []);
    // 获取下一个对象的 key 值，用于页面上关闭标签，切换下一页面
    const nextPath = useCallback(
      (key: string) => {
        const index = state.findIndex((value) => value.key === key);
        const activeItem = state.find((item) => item.active);
        if (activeItem && activeItem.key !== key) return activeItem.key;
        // 如果存在下一项，则返回下一项的 key，否则返回前一项的 key
        if (state[index + 1]) return state[index + 1].key;
        else if (state[index - 1]) return state[index - 1].key;
        // 如果上下均不存在，则返回 null
        return null;
      },
      [state]
    );

    const setName = useCallback((key: string, name: string) => {
      setState((state) => {
        const newState = Array.from(state);
        const itemIndex = newState.findIndex((item) => item.key === key);
        if (itemIndex > -1) {
          newState[itemIndex].name = name;
          newState[itemIndex].title = name;
        }

        return newState;
      });
    }, []);

    // Provider 中存储的 value，使用 useMemo 进行优化
    // 暴露给外部使用的 list，进行 sort 排序操作，alwaysShow 为 true 的项排在前方
    const contextValue = useMemo(() => {
      const keepAliveList = Array.from(state)
        .sort((a, b) => {
          if (!a.alwaysShow) return 1;
          return (b.weight ?? 0) - (a.weight ?? 0);
        })
        .sort((a, b) => {
          if (a.alwaysShow && b.alwaysShow) return 0;
          return a.alwaysShow ? -1 : 1;
        });

      const current = keepAliveList.find((item) => item.active);
      return { current, keepAliveList, push, remove, clear, nextPath, setName };
    }, [state, push, remove, clear, nextPath, setName]);

    return <Provider value={contextValue}>{children}</Provider>;
  };

  const useKeepAlive = () => {
    const context = useContext(KeepAliveContext);
    if (!context) {
      throw new Error("useKeepAlive muse be used within a KeepAlivePrivider");
    }
    return context;
  };

  return {
    KeepAliveProvider,
    useKeepAlive,
    KeepAliveConsumer: Consumer,
  };
};

export default initKeepAlive;
