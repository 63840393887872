import { cloneElement } from "react";
import { isNil } from "lodash-es";
import {
  useFormContext,
  Controller,
  FieldValues,
  ControllerProps,
} from "react-hook-form";

export interface FormItemProps<T extends FieldValues>
  extends Omit<ControllerProps<T>, "control" | "render"> {
  valueKey?: string;
  onChangeKey?: string;
  errorKey?: string | false;
  children: ControllerProps<T>["render"] | React.ReactElement;
  handleChange?: (value: any, v2?: any) => any | void;
  beforeChange?: (value: any) => void;
}

export const Item = <T extends FieldValues>({
  children,
  valueKey = "value",
  onChangeKey = "onChange",
  errorKey = "helperText",
  handleChange,
  beforeChange,
  ...controlProps
}: FormItemProps<T>) => {
  const { control } = useFormContext<T>();
  return (
    <Controller
      {...controlProps}
      control={control}
      render={(props) => {
        if (typeof children === "function") return children(props);
        const newProps: Record<string, any> = {
          [valueKey]: props.field.value,
          [onChangeKey]: (value: any, v2: any) => {
            if (handleChange) {
              const result = handleChange(value, v2);
              if (!isNil(result)) props.field.onChange(result);
            } else props.field.onChange(value);
            beforeChange?.(value);
          },
        };
        if (errorKey) {
          newProps.intent = "danger";

          newProps[errorKey] = props.fieldState.error?.message;
        }

        return cloneElement(children, newProps);
      }}
    />
  );
};
