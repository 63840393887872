import { Fragment, useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { useAuth } from "@/shared/libs/auth";
import { toaster } from "@/shared/libs/toaster";
import { Button, Classes } from "@blueprintjs/core";

import { Form, Input, InputPassword } from "@/shared/components/Form";
import { useForm } from "@/shared/libs/react-hook-form";

import { loginSchema, LoginCredentialsDTO } from "@/shared/api/system/auth";
import { useNavigation } from "@/shared/libs/navigation";
import type { ResponseType } from "@/shared/types";
import { responseMessageKey } from "@/config";

export const AuthLogin = () => {
  const { login, isLoggingIn } = useAuth();
  const { refetchNavigate } = useNavigation();
  const methods = useForm({
    schema: loginSchema,
  });

  const navigate = useNavigate();

  const showErrorDialog = useCallback((error: ResponseType) => {
    const errorMsg = error[responseMessageKey];
    if (typeof errorMsg !== "string") return;
    toaster.current?.show({
      message: errorMsg,
      intent: "danger",
      icon: "error",
      timeout: 3000,
    });
    throw error;
  }, []);

  const handleSubmit = useCallback(
    async (values: LoginCredentialsDTO) => {
      login(values)
        .then(() => {
          // 登陆成功后， 调用获取路由的接口，并返回一个 Promise
          // 使用 toaster 显示顶部成功信息
          const fetch = refetchNavigate();
          toaster.current?.show({
            message: "账户登录成功，将自动进行跳转",
            intent: "success",
            icon: "tick",
            timeout: 1000,
            // 成功信息消失后（1000 tick）执行
            // 检查并等待 fetch 的状态成为 resolve，随即切换路由至 '/'
            onDismiss: async () => {
              await fetch;
              navigate("/");
            },
          });
        })
        .catch(showErrorDialog);
    },
    [login, showErrorDialog, navigate, refetchNavigate]
  );

  return (
    <Fragment>
      <div className="mb-4">
        <h3 className="bp4-heading">登录系统</h3>
        <div className={Classes.TEXT_MUTED}>输入账户名称及密码登录到系统</div>
      </div>
      <Form<LoginCredentialsDTO>
        onSubmit={handleSubmit}
        onError={(e) => console.log("e", e)}
        methods={methods}
      >
        <Input
          label="登录账户"
          field="empNo"
          placeholder="请输入用户登录账户"
        />
        <InputPassword
          label="登录密码"
          field="password"
          placeholder="请输入账户登录密码"
        />
        <div className="mt-2 mb-2 flex justify-between w-full">
          <div></div>
          {/* <Button minimal>忘记密码</Button> */}
        </div>

        <Button
          className={"mt-4"}
          loading={isLoggingIn}
          type={"submit"}
          fill
          intent={"primary"}
        >
          登录
        </Button>
      </Form>
    </Fragment>
  );
};
