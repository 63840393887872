import request from "@/shared/libs/axios";
import { CRUDModel } from "@/shared/api/models/crud.model";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { useDataQuery } from "@/shared/hooks/useDataQuery";
import type { DataHookOptions } from "@/shared/types/data";

import { formSchema } from "./schema";
import { DataType } from "./type";
export * from "./type";
export * from "./schema";

class Api extends CRUDModel<DataType, typeof formSchema> {
  constructor() {
    super({
      controller: "sys",
      model: "user",
      schema: formSchema,
      dbAbbr: "su",
      apiMap: {
        find: "info",
        create: "save",
        update: "update",
      },
    });
  }

  findByOfficeCode = (code: string) => {
    return request.get<DataType[]>(
      this.getUrl("findByOfficeCode") + `/${code}`
    );
  };

  initPasswordById = (id: string) => {
    return request.get<DataType[]>(this.getUrl("initPasswordById") + `/${id}`);
  };
  password = (payload: Record<string, string>) => {
    return request.post(this.getUrl("password"), payload);
  };

  findByProfitCode = (code: string) => {
    return request.get<DataType[]>(
      this.getUrl("findCurAndChildDeptUserByProfitCode") + `/${code}`
    );
  };
  selectTagUserByDictCode = (code: string) => {
    return request.get<{ name: string; empNo: string }[]>(
      this.getUrl("selectTagUserByDictCode") + `/${code}`
    );
  };

  findProductionCenterUser = () => {
    return request.post<DataType[]>(this.getUrl("findProductionCenterUser"));
  };
}
const api = new Api();

export default api;

export const useList = (options: DataHookOptions) => {
  return useDataQuery(api.queryPage, {
    abbr: api.getConditionAbbr(),
    cacheKey: "user-list",
    ...options,
  });
};

export const useListByProfitCode = (
  code: string,
  options?: Omit<
    UseQueryOptions<DataType[], { msg: string }, DataType[], string[]>,
    "initialData" | "queryFn" | "queryKey"
  >
) => {
  return useQuery(
    ["user-list-by-profitcode", code],
    () => api.findByProfitCode(code),
    {
      ...options,
    }
  );
};

export const useProductionCenterList = (
  options?: Omit<
    UseQueryOptions<DataType[], { msg: string }, DataType[], string[]>,
    "initialData" | "queryFn" | "queryKey"
  >
) => {
  return useQuery(
    ["user-list-by-production-center"],
    () => api.findProductionCenterUser(),
    {
      ...options,
    }
  );
};

export const useInfo = (
  id: string,
  options?: Omit<
    UseQueryOptions<DataType, { msg: string }, DataType, string[]>,
    "initialData" | "queryFn" | "queryKey"
  >
) => {
  return useQuery(["user-info", id], () => api.find(id), {
    ...options,
  });
};
