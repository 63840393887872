import { v4 as uuidv4 } from "uuid";
import { forwardRef, Fragment, useImperativeHandle, useState } from "react";

import { Button, Icon } from "@blueprintjs/core";
import { Modal } from ".";

type ItemType = Record<string, any> & any;
export interface MultiModalRef {
  open: (item?: ItemType) => string;
  close: (id: string) => void;
  hidden: (id: string) => void;
}
export interface MultiModalProps {
  modalWidth?: number;
  modalHeight?: number;
  minWidth?: number;
  minHeight?: number;
  titleRender: (item?: ItemType) => string;
  contentRender: (props: modalItem) => JSX.Element;
  onClose?: (props: modalItem) => void;
}

type modalItem = { id: string; item?: ItemType };
export const MultiModal = forwardRef<MultiModalRef, MultiModalProps>(
  ({ titleRender, contentRender: ContentRender, onClose, ...props }, ref) => {
    const [lists, setLists] = useState<modalItem[]>([]);
    const [hiddenList, setHiddenList] = useState<string[]>([]);

    const open: MultiModalRef["open"] = (item) => {
      const id = uuidv4();
      setLists((state) => [{ id, item } as modalItem].concat(state));
      return id;
    };
    const hidden: MultiModalRef["hidden"] = (id) => {
      setHiddenList((state) => {
        if (state.includes(id)) return state;
        return state.concat(id);
      });
    };
    const close: MultiModalRef["close"] = (id) => {
      setLists((state) => state.filter((item) => item.id !== id));
      setHiddenList((state) => state.filter((item) => item !== id));
    };

    useImperativeHandle(ref, () => ({
      open,
      hidden,
      close,
    }));

    const show = (id: string) => {
      setHiddenList((state) => {
        return state.filter((item) => item !== id);
      });
    };
    const checkHidden = (id: string) => {
      return hiddenList.some((item) => item === id);
    };
    const upperModalIndex = (id: string) => {
      setLists((state) => {
        const item = state.find((item) => item.id === id);
        if (!item) return state;
        const index = state.indexOf(item);
        if (index === 0) return state;
        const newList = Array.from(state);
        newList.splice(index, 1);
        return [item].concat(newList);
      });
    };
    return (
      <Fragment>
        {lists.map((modalItem, index) => (
          <Modal
            showHidden
            key={modalItem.id}
            isOpen={!checkHidden(modalItem.id)}
            title={titleRender(modalItem.item)}
            onDrag={() => upperModalIndex(modalItem.id)}
            onHiddenClick={() => hidden(modalItem.id)}
            onClose={() => {
              onClose?.(modalItem);
              close(modalItem.id);
            }}
            zIndex={100 - index}
            width={props.modalWidth}
            height={props.modalHeight}
            minWidth={props.minWidth}
            minHeight={props.minHeight}
          >
            <ContentRender {...modalItem} />
          </Modal>
        ))}
        {!!hiddenList.length && (
          <div
            className={
              "absolute left-0 right-0 bottom-[36px] flex h-[30px] items-center px-2 gap-x-2 bg-white"
            }
            style={{ borderTop: "solid 1px rgba(0,0,0,0.2)" }}
          >
            {hiddenList.map((id) => {
              const modal = lists.find((item) => item.id === id)!;
              return (
                <Button
                  key={id}
                  minimal
                  small
                  rightIcon={<Icon size={12} icon="arrow-top-right" />}
                  onClick={() => show(id)}
                  text={
                    titleRender(modal.item) ?? `窗口${lists.indexOf(modal) + 1}`
                  }
                />
              );
            })}
          </div>
        )}
      </Fragment>
    );
  }
);

MultiModal.displayName = "MultiModal";
