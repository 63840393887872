// 用户登录状态及用户信息的 model
// 维护项目里需要使用到的 user 信息
// 以及在用户注销登录后，清空 query 缓存的所有数据
import type { PropsWithChildren } from "react";
import { createContext, useCallback, useMemo, useContext } from "react";

import { useQueryClient, useQuery, useMutation } from "@tanstack/react-query";
import type {
  UseMutateAsyncFunction,
  RefetchOptions,
  QueryObserverResult,
} from "@tanstack/react-query";

/**
 * 初始化 Model 的参数，需要传入四个类型值
 * @U User 即用户 type
 * @E Error 即错误类型
 * @L Login 请求参数
 * @R Register 请求参数
 */
export interface InitAuthModelOptions<U, E, L, R> {
  key?: string;
  loadUser: (data?: any) => Promise<U | null>;
  loginFn: (data: L) => Promise<U>;
  registerFn: (data: R) => Promise<U>;
  logoutFn: () => Promise<void>;
  LoaderComponent?: () => JSX.Element;
  ErrorComponent?: ({ error }: { error?: E }) => JSX.Element;
  waitInitial?: boolean;
}

export interface AuthContext<U, E, L, R> {
  /** 用户信息数据 */
  user: U | null;
  /** 用户登录的方法，传入参数为 login payload */
  login: UseMutateAsyncFunction<U, E, L>;
  /** 用户登出的方法，登出将清空全部 query 缓存 */
  logout: UseMutateAsyncFunction<any, any, void, any>;
  /** 用户注册的方法，传入参数为 register payload */
  register: UseMutateAsyncFunction<U, E, R>;
  /** 用于更新用户信息 */
  refetchUser: (
    options?: RefetchOptions
  ) => Promise<QueryObserverResult<U | null, E>>;
  /** 是否正在登录中 */
  isLoggingIn: boolean;
  /** 是否正在登出中 */
  isLoggingOut: boolean;
  /** 是否正在注册中 */
  isRegistering: boolean;
  /** 获取用户信息捕捉到的错误 */
  error: E | null;
}

/**
 * 创建 Provider 的参数，泛形 U/E/L/R 对应
 * User: 用户类型
 * Error: 错误类型
 * LoginCredentials: 登录参数类型
 * RegisterCredentials: 注册参数类型
 * @param config
 */
export function initQueryAuth<U, E, L, R>(
  config: InitAuthModelOptions<U, E, L, R>
) {
  // 解构并创建 config 的默认值
  const {
    key = "auth-user",
    waitInitial = true,
    LoaderComponent = () => <div>Loading...</div>,
    ErrorComponent = (error) => <div>{JSON.stringify(error, null, 2)}</div>,
  } = config;
  // 解构需要使用到的函数
  const { loginFn, registerFn, logoutFn, loadUser } = config;

  // 创建 Context
  const AuthContext = createContext<AuthContext<U, E, L, R> | null>(null);

  // 创建 Provider
  const AuthProvider = ({ children }: PropsWithChildren) => {
    const queryClient = useQueryClient();

    const {
      data: user,
      error,
      status,
      isLoading,
      isSuccess,
      isPaused,
      refetch,
    } = useQuery<U | null, E>([key], loadUser);

    // 修改 queryClient 中的 auth 值
    const setUser = useCallback(
      (user: U) => {
        queryClient.setQueryData([key], user);
      },
      [queryClient]
    );
    // 登录的 mutation
    const loginMutation = useMutation<U, E, L>(loginFn, {
      onSuccess(data) {
        setUser(data);
      },
    });
    // 注册的 mutation
    const registerMutation = useMutation<U, E, R>(registerFn, {
      onSuccess(data) {
        setUser(data);
      },
    });
    // 登出的 mutation
    // 登出后 clear 全部状态
    const logoutMutation = useMutation(logoutFn, {
      onSuccess() {
        queryClient.clear();
      },
    });
    // 设置在 Context 上的值
    const contextValue = useMemo<AuthContext<U, E, L, R>>(
      () => ({
        user: user ?? null,
        error,
        refetchUser: refetch,
        login: loginMutation.mutateAsync,
        logout: logoutMutation.mutateAsync,
        register: registerMutation.mutateAsync,
        isLoggingIn: loginMutation.isLoading,
        isLoggingOut: logoutMutation.isLoading,
        isRegistering: registerMutation.isLoading,
      }),
      [
        user,
        error,
        refetch,
        loginMutation.mutateAsync,
        loginMutation.isLoading,
        registerMutation.mutateAsync,
        registerMutation.isLoading,
        logoutMutation.mutateAsync,
        logoutMutation.isLoading,
      ]
    );

    // 如果请求状态是 success 或者关闭了等待初始化，则显示内容
    if (isSuccess || !waitInitial) {
      return (
        <AuthContext.Provider value={contextValue}>
          {children}
        </AuthContext.Provider>
      );
    }
    // 处于 loading 或者暂停的状态下，显示 loaing 状态
    if (isLoading || isPaused) {
      return <LoaderComponent />;
    }

    // 如果用户信息捕捉到了错误，则显示错误状态
    if (error) {
      return <ErrorComponent error={error} />;
    }

    // 如果未满足以上的状态拦截，则显示最终的统一 status
    return <div>Unhandle status: {status}</div>;
  };

  // 暴露给函数式组件的 hooks
  const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
      throw new Error("useAuth must be used within a AuthProvider");
    }
    return context;
  };

  // Consumer 用于提供给 class 组件去获取 context 中的值
  return { AuthProvider, useAuth, AuthConsumer: AuthContext.Consumer };
}
