import request from "@/shared/libs/axios";

export type UserInfo = {
  id: string;
  createTime: string;
  createBy: string;
  updateTime: string | null;
  updateBy: string | null;
  recordStatus: number;
  remark: null;
  orderNum: number;
  name: string;
  username: string;
  empNo: string;
  employeeNo: string | null;
  password: string;
  salt: string;
  email: string;
  mobile: string;
  costCenterCode: string | null;
  avatar: string | null;
  roleIdList: string[];
  departmentIdList: string[] | null;
};

export const getUser = async () => {
  return request.post<UserInfo>("/sys/user/info");
};
