import { useFormContext, Controller } from "@/shared/libs/react-hook-form";
import { FieldWrapper, FieldWrapperPassThroughProps } from "../../index";
import DatePickerComponent, {
  DatePickerProps as DatePickerComponentProps,
} from "@/shared/components/DatePicker";

export type DatePickerProps = FieldWrapperPassThroughProps &
  DatePickerComponentProps & {
    field: string;
    required?: boolean;
    defaultValue?: string | number;
  };

export const DatePicker = ({
  className,
  field,
  required,
  defaultValue,
  label,
  error,
  inline,
  labelNum,
  inputProps,
  ...datePickerProps
}: DatePickerProps) => {
  const methods = useFormContext();

  return (
    <Controller
      control={methods?.control}
      defaultValue={defaultValue ?? ""}
      name={field}
      render={(props) => {
        return (
          <FieldWrapper
            className={className}
            label={label}
            labelNum={labelNum}
            inline={inline}
            error={error ?? methods?.formState.errors[field]}
            required={required}
          >
            <DatePickerComponent
              {...datePickerProps}
              value={props.field.value}
              onChange={props.field.onChange}
              inputProps={Object.assign(inputProps ?? {}, props.field)}
              popoverProps={{ minimal: true, position: "top-left" }}
            />
          </FieldWrapper>
        );
      }}
    />
  );
};
