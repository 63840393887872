import { initQueryNavigate } from "@/shared/models/navigate";
import type { InitNavigationModelOptions } from "@/shared/models/navigate";

import { Button, NonIdealState } from "@blueprintjs/core";

import PageLoader from "@/shared/components/PageLoader";

import { getNavigate } from "@/shared/api/system/menu";
import type { NavigationType } from "@/shared/api/system/menu";

import { token as tokenStorage } from "@/shared/utils/storage";

const logoutFn = async () => {
  tokenStorage.clearToken();
  window.location.assign(window.location.origin);
};

const loadNavigate = async () => {
  if (!tokenStorage.getToken()) return null;
  return getNavigate();
};
const option: InitNavigationModelOptions<NavigationType[], string> = {
  loadNavigate,
  LoaderComponent: () => <PageLoader />,
  ErrorComponent: ({ error }) => {
    return (
      <NonIdealState
        className="p-52"
        icon={"error"}
        iconSize={48}
        description={error}
        action={
          <Button className="mt-2" intent={"primary"} onClick={logoutFn}>
            返回首页
          </Button>
        }
      />
    );
  },
};

export const { useNavigation, NavigationConsumer, NavigationProvider } =
  initQueryNavigate(option);
