import { RouteObject, Navigate } from "react-router-dom";
import { AuthLayout } from "@/features/Auth";
import PageNotFound from "@/pages/404";

export const publicRoutes: RouteObject[] = [
  {
    path: "/auth/*",
    element: <AuthLayout />,
  },
  {
    path: "/404",
    element: <PageNotFound />,
  },
];

export const notFoundRoute: RouteObject = {
  path: "*",
  element: <Navigate to="/404" replace />,
};
