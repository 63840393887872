import styled, { css } from "styled-components";
import { FormGroup, Colors } from "@blueprintjs/core";

export const FormContainer = styled.form``;

interface IFormLabelProps {
  labelNum?: number;
  required?: boolean;
}
export const FormLabel = styled(FormGroup)<IFormLabelProps>`
  margin: 0 0 6px;

  .bp4-label {
    ${(props) =>
      props.required &&
      css`
        &::before {
          content: "•";
          color: ${Colors.RED3};
          margin-right: 2px;
        }
      `}
  }

  .bp4-form-content {
    height: 100%;
    min-height: 0;
  }
  &.bp4-inline {
    align-items: center;

    &.bp4-intent-danger {
      align-items: start;
    }
    .bp4-form-content {
      height: auto;
      flex: 1;
    }

    .bp4-label {
      ${(props) =>
        props.labelNum &&
        css`
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: ${props.labelNum}em;
        `}
    }
  }
`;
