import { z } from "zod";
import { isMail } from "@/shared/utils/regex";

export const formSchema = z.object({
  id: z.string().optional(),
  name: z.string().min(1, "员工姓名不能为空"),
  empNo: z.string().or(z.number()),
  password: z.string().min(1, "请输入用户初始密码").optional(),
  mobile: z.string().min(1, "请输入联系方式"),
  email: z.string().regex(isMail(true), "邮箱格式不正确"),
  roleIdList: z.array(z.string().or(z.number())).min(1, "请选择员工角色信息"),
  deptIdList: z
    .array(z.string().or(z.number()))
    .min(1, "请选择员工所处部门信息"),
  mainDeptId: z.string().min(1, "请选择用户主要部门").or(z.number()),
  remark: z.string().default(""),
});

export type SchemaDTO = z.infer<typeof formSchema>;
