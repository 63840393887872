import { z } from "zod";
import { useMutation } from "@tanstack/react-query";
import { useToast } from "@/shared/hooks";

import { Modal, ModalProps } from "@/components";
import { Button } from "@blueprintjs/core";
import { useForm } from "@/shared/libs/react-hook-form";
import { Form, InputPassword } from "@/shared/components/Form";
import userApi from "@/shared/api/system/user";

const schema = z
  .object({
    password: z.string().min(1, "请输入原密码"),
    newPassword: z.string().min(1, "请输入新密码"),
    renewPassword: z.string().min(1, "请确认输入密码"),
  })
  .refine((val) => val.newPassword === val.renewPassword, {
    message: "两次输入的密码不正确，请检查",
    path: ["renewPassword"],
  });

interface IPasswordChangeProps extends ModalProps {
  onSuccess: () => void;
}
export const PasswordChange = (props: IPasswordChangeProps) => {
  const { success, fail } = useToast({ timeout: 1000 });
  const methods = useForm({ schema: schema });

  const { mutateAsync, isLoading } = useMutation(userApi.password, {
    onSuccess: async () => {
      await success(`密码修改成功`);
      props.onSuccess();
    },
    onError: ({ msg }) => fail({ message: msg }),
  });
  const handleSubmit = (value: z.infer<typeof schema>) => {
    mutateAsync(value);
  };

  const handleCancel = () => {
    methods.reset();
    props.onClose?.();
  };
  return (
    <Modal
      {...props}
      width={340}
      height={340}
      minWidth={340}
      minHeight={340}
      title="修改密码"
    >
      <Form
        className="p-2 h-full flex flex-col overflow-auto"
        methods={methods}
        onSubmit={handleSubmit}
      >
        <InputPassword label="原密码" field="password" placeholder="请输入" />
        <InputPassword
          label="新密码"
          field="newPassword"
          placeholder="请输入"
        />
        <InputPassword
          label="确认新密码"
          field="renewPassword"
          placeholder="请输入"
        />
        <div className="flex-grow" />
        <div className={"flex gap-2 mt-4"}>
          <Button intent="primary" loading={isLoading} type="submit">
            确认修改
          </Button>
          <Button disabled={isLoading} onClick={handleCancel}>
            取消
          </Button>
        </div>
      </Form>
    </Modal>
  );
};
