// ========== 系统使用到的环境变量 ==========
export const isDev = process.env.NODE_ENV === "development";
// ================= 结束 =================

// ========== 系统使用到的参数配置 ==========
export const projectTitle = "朱师傅 ERP 管理";
export const storagePrefix = "fine_react_";
export const baseURL = "/api";
export const testURL = "/test";

export const defaultPageSize = 50;
export const pageSizeOptions = [20, 50, 100, 150, 200, 300, 500, 1000];
export const maxKeepAliveNum = 15;

// ================= 结束 =================

// ========== 系统使用到的请求配置 ==========
// 在 config 中统一设置 response 的键名、status 值
// 用于适配不同后端的返回格式
// 返回的数据必须要求后端包含在 data 中，data 以外的数据不会解析
export const responseMessageKey = "msg";
export const responseCodeKey = "code";
export const responseDataKey = "data";

export const successCode = [2000];
export const invalidCode = [401];
export const noPermissionCode = [401];
// ================= 结束 =================
