export const appNavBarCollaspedWidth = 48;
export const appNavBarWidth = 240;
export const appHeaderBarHeight = 48;
export const minViewportWidth = 1024;
export const keepAliveHeight = 30;

export const paginationHeight = 36;

export const appPadding = {
  small: 6,
  middle: 12,
  large: 18,
};
