import { useCallback } from "react";
import classNames from "classnames";

import {
  Button,
  ButtonProps,
  Classes,
  IconName,
} from "@blueprintjs/core";
import { Select2, ItemRenderer } from "@blueprintjs/select";
import { MenuItem2 } from "@blueprintjs/popover2";

import { getIconNames, NONE } from "./iconNames";
import type { IconNameType } from "./iconNames";

export interface IconSelectProps extends Omit<ButtonProps, "disabled"> {
  disabled?: boolean;
  value?: IconName;
  placeholder?: string;
  onChange?: (iconName?: IconName) => void;
  // inputProps?: IInputGroupProps2;
}

const TypedSelect = Select2.ofType<IconNameType>();
const ICON_NAMES = getIconNames();

const IconSelect = ({
  disabled,
  value,
  placeholder = "输入图标名筛选",
  // inputProps,
  onChange,
  ...buttonProps
}: IconSelectProps) => {
  const handleIconChange = useCallback(
    (value: IconNameType) => {
      if (typeof onChange === "function") {
        onChange(value === NONE ? undefined : value);
      }
    },
    [onChange]
  );
  const itemPredicate = useCallback(
    (query: string, iconName: IconNameType) => {
      if (iconName === NONE) return true;
      if (!query) return iconName === value;
      return iconName.toLowerCase().includes(query.toLocaleLowerCase());
    },
    [value]
  );

  const itemRenderer = useCallback<ItemRenderer<IconNameType>>(
    (icon, { handleClick, handleFocus, modifiers }) => {
      if (!modifiers.matchesPredicate) return null;
      return (
        <MenuItem2
          selected={modifiers.active}
          icon={icon === NONE ? undefined : icon}
          key={icon}
          onClick={handleClick}
          onFocus={handleFocus}
          text={icon}
        />
      );
    },
    []
  );

  return (
    <TypedSelect
      disabled={disabled}
      items={ICON_NAMES}
      itemPredicate={itemPredicate}
      itemRenderer={itemRenderer}
      noResults={<MenuItem2 disabled text="无此图标" />}
      onItemSelect={handleIconChange}
      popoverProps={{ minimal: true, position: "bottom-left" }}
      inputProps={Object.assign({}, { placeholder })}
    >
      <Button
        {...buttonProps}
        alignText="left"
        className={classNames(
          Classes.TEXT_OVERFLOW_ELLIPSIS,
          buttonProps.className
        )}
        disabled={disabled}
        fill={true}
        icon={value}
        text={value || NONE}
        rightIcon="caret-down"
      />
    </TypedSelect>
  );
};

export default IconSelect;
