import styled from "styled-components";
import { storage } from "@/shared/utils/storage";

import { Fragment, useState, useEffect, useRef } from "react";
import { NavLink as NavLinkComponents } from "react-router-dom";
import { Button, Icon, Collapse } from "@blueprintjs/core";
import { Tooltip2 } from "@blueprintjs/popover2";

import { NavigationType } from "@/shared/api/system/menu";
import { sizes } from "@/shared/styles";

interface ISiderBarProps {
  currentNav?: NavigationType;
  collapsed: boolean;
  onCollapse: () => void;
}
export const SiderBar = ({
  currentNav,
  collapsed,
  onCollapse,
}: ISiderBarProps) => {
  const [expand, setExpand] = useState("");
  const isTest = useRef<boolean>(!!storage.get("testFlag"));

  useEffect(() => {
    setExpand("");
  }, [currentNav]);

  const { children: navList } = currentNav ?? {};
  if (!navList?.length || !currentNav) return null;

  return (
    <div
      className="flex-shrink-0 absolute top-0 bottom-0 left-0 overflow-hiddenn bg-white"
      style={{
        transition: "all 0.1s",
        borderRight: "solid 1px rgba(0,0,0,0.1)",
        width: collapsed ? sizes.appNavBarCollaspedWidth : sizes.appNavBarWidth,
        padding: `${sizes.appPadding.middle}px 0`,
      }}
    >
      <div className="h-full overflow-y-auto overflow-x-hidden flex flex-col">
        <div className="flex flex-col gap-1">
          {navList.map((item) => {
            const hasChildren = item.type === 0 && !!item.children?.length;
            if (item.noMenu) return null;

            return (
              <Fragment key={item.id}>
                <NavLink
                  className={isTest.current ? "test" : undefined}
                  to={`./${currentNav.path}/${item.path}`}
                  onClick={(e) => {
                    if (hasChildren) {
                      e.preventDefault();
                      if (collapsed) {
                        onCollapse();
                        setExpand(item.path);
                      } else if (expand === item.path) setExpand("");
                      else setExpand(item.path);
                    }
                  }}
                >
                  {collapsed ? (
                    <Tooltip2
                      className="px-1 w-full"
                      content={item.name}
                      position="right"
                    >
                      <Icon icon={item.icon} />
                    </Tooltip2>
                  ) : (
                    <div className="pl-1 relative w-full flex gap-2 items-center">
                      <Icon className="flex-shrink-0" icon={item.icon} />
                      <div className="flex-1 min-w-0">{item.name}</div>
                      {hasChildren && (
                        <div className="flex-shrink-0">
                          <Icon
                            icon={
                              expand === item.path
                                ? "caret-down"
                                : "caret-right"
                            }
                          />
                        </div>
                      )}
                    </div>
                  )}
                </NavLink>
                {hasChildren && !collapsed && (
                  <Collapse isOpen={expand === item.path}>
                    {item.children.map((citem) => {
                      if (citem.noMenu) return null;
                      return (
                        <CNavLink
                          key={citem.id}
                          className={isTest.current ? "test" : undefined}
                          to={`./${currentNav.path}/${item.path}/${citem.path}`}
                        >
                          <div className="pl-3">
                            <Icon className="mr-2" icon={citem.icon} />
                            {citem.name}
                          </div>
                        </CNavLink>
                      );
                    })}
                  </Collapse>
                )}
              </Fragment>
            );
          })}
        </div>
      </div>

      <div className="absolute bottom-2 right-2 z-10">
        <Button
          minimal
          icon={collapsed ? "menu-open" : "menu-closed"}
          onClick={onCollapse.bind(undefined)}
        />
      </div>
    </div>
  );
};

const NavLink = styled(NavLinkComponents)`
  all: unset;
  padding: 0 12px;
  white-space: nowrap;
  display: flex;
  align-items: center;
  height: 36px;
  color: #333;

  > span.bp4-popover2-target {
    height: 36px;
    line-height: 36px;
  }

  &:hover,
  &.active {
    color: white;
    background-color: #2b8608;
    text-decoration: none;

    &.test {
      background-color: #ba6f0b;
    }
  }
`;

const CNavLink = styled(NavLinkComponents)`
  all: unset;
  padding: 0 12px;
  white-space: nowrap;
  display: flex;
  align-items: center;
  height: 36px;
  color: #333;

  &:hover,
  &.active {
    /* color: #2b8608; */
    color: white;
    background-color: #2b8608;
    text-decoration: none;

    &.test {
      /* color: #ba6f0b; */
      background-color: #ba6f0b;
    }
  }
`;
