import classnames from "clsx";
import { forwardRef, ComponentProps, ReactNode } from "react";

interface IPageWrapperProps extends ComponentProps<"div"> {
  headerRender?: ReactNode;
  headerClassName?: string;
  contentClassName?: string;
}
export const PageWrapper = forwardRef<HTMLDivElement, IPageWrapperProps>(
  (
    {
      className,
      children,
      headerRender,
      headerClassName,
      contentClassName,
      ...props
    },
    ref
  ) => {
    return (
      <div
        className={classnames("h-full flex flex-col", className)}
        {...props}
        ref={ref}
      >
        {headerRender && (
          <div
            className={classnames("page-header flex-shrink-0", headerClassName)}
          >
            {headerRender}
          </div>
        )}
        <div
          className={classnames(
            "page-content flex-1 min-h-0 relative overflow-hidden",
            contentClassName
          )}
        >
          {children}
        </div>
      </div>
    );
  }
);

PageWrapper.displayName = "PageWrapper";
