import { Ring } from "@uiball/loaders";

const PageLoader = () => {
  return (
    <div className="w-full h-full p-52 flex items-center justify-center">
      <Ring size={40} lineWeight={5} speed={2} color="black" />
    </div>
  );
};

export default PageLoader;
