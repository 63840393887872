import { useForm as useRHF } from "react-hook-form";
import type { UseFormProps, DeepPartial, FieldValues } from "react-hook-form";

import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import type { Schema } from "zod";

export type FormProps<T extends FieldValues, TSchema extends Schema> = {
  options?: UseFormProps<T>;
  schema?: TSchema;
  defaultValues?: DeepPartial<T>;
};

export const useForm = <
  S extends Schema = Schema,
  T extends FieldValues = z.infer<S>
>({
  options,
  schema,
  defaultValues,
}: FormProps<T, S>) => {
  const methods = useRHF<T>({
    ...options,
    resolver: schema && zodResolver(schema),
    defaultValues,
  });

  return methods;
};

export * from "react-hook-form";
