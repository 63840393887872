import request from "@/shared/libs/axios";

export type RegisterCredentialsDTO = {
  name: string;
  username: string;
  password: string;
};

export const register = async (data: RegisterCredentialsDTO) => {
  return request.post<string, RegisterCredentialsDTO>("/sys/auth/register", data);
};
