import { Navigate } from "react-router-dom";
import { useAuth } from "@/shared/libs/auth";

/**
 * 访问页面 index 路由的落地页
 * 如果用户信息不存在（即未登录）则跳转至 auth 路由，反之进入 project 路由
 */
const LandingPage = () => {
  const { user } = useAuth();
  if (!user) return <Navigate to="/auth" replace />;
  return <Navigate to={"/project"} replace />;
};

export default LandingPage;
