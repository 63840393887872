import { useCallback, useMemo, useState } from "react";
import type { ReactNode } from "react";
import { Tree, Checkbox } from "@blueprintjs/core";
import type { TreeProps, TreeNodeInfo } from "@blueprintjs/core";

import { treeFlat, TreeFlatItem } from "@/shared/utils/array";

export interface TreeNode {
  value: string;
  label: ReactNode;
  children?: this[];
}
export type TreeInfo = TreeNodeInfo<{
  checked: boolean;
  indeterminate: boolean;
}>;

export interface TreeSelectProps
  extends Omit<
    TreeProps,
    "contents" | "onNodeCollapse" | "onNodeExpand" | "onNodeClick"
  > {
  disabled?: boolean;
  nodes: TreeNode[];
  isSelect?: boolean;
  multipleSelect?: boolean;
  checkedList: string | string[];
  onChange: (value: string | string[]) => void;
  defaultExpandAll?: boolean;
}

const TreeSelect = ({
  className,
  nodes,
  isSelect,
  multipleSelect,
  checkedList,
  disabled,
  defaultExpandAll,
  onChange,
  ...props
}: TreeSelectProps) => {
  const [expandKeys, setExpandKeys] = useState<string[]>(() => {
    if (defaultExpandAll) {
      const getExpandKeyHandle = (prev: string[], item: TreeNode): string[] => {
        if (item.children?.length) {
          prev.push(item.value);
          const childrenKeys = item.children.reduce(getExpandKeyHandle, []);
          return prev.concat(childrenKeys);
        }

        return prev;
      };
      return nodes.reduce(getExpandKeyHandle, []);
    } else {
      return [];
    }
  });

  const flatNodesMap = useMemo(() => {
    return treeFlat(nodes, "value", "children").reduce((prev, item) => {
      prev.set(item.value, item);
      return prev;
    }, new Map<string, TreeFlatItem<TreeNode>>());
  }, [nodes]);
  const handleClick = useCallback(
    (checkedItem: TreeInfo) => {
      if (isSelect) {
        const { nodeData, id } = checkedItem;
        if (nodeData?.checked) {
          if (Array.isArray(checkedList)) {
            const newList = checkedList.filter((checked) => checked !== id);
            onChange?.(newList);
          } else {
            onChange?.("");
          }
        } else {
          if (multipleSelect && Array.isArray(checkedList)) {
            onChange?.(checkedList.concat(id as string));
          } else {
            if (Array.isArray(checkedList)) {
              onChange?.([id as string]);
            } else {
              onChange?.(id as string);
            }
          }
        }
      } else {
        const parentIds = getParentIds(flatNodesMap, checkedItem.id as string)
          .reverse()
          .filter((parents) => parents !== checkedItem.id);
        const childrenValues =
          checkedItem.childNodes?.reduce(getNodeValueHandle, []) ?? [];

        if (typeof onChange === "function") {
          const { nodeData, id } = checkedItem;
          if (!nodeData?.checked) {
            const newList = (checkedList as string[]).concat(
              id as string,
              childrenValues,
              parentIds
            );
            onChange?.(Array.from(new Set(newList)));
          } else if (nodeData.checked && nodeData.indeterminate) {
            const newList = (checkedList as string[]).concat(
              id as string,
              childrenValues
            );
            onChange?.(Array.from(new Set(newList)));
          } else {
            const newList = [id as string].concat(childrenValues);
            const newCheckedList = (checkedList as string[]).filter(
              (value) => !newList.includes(value)
            );
            onChange?.(newCheckedList);
          }
        }
      }
    },
    [flatNodesMap, onChange, checkedList, isSelect, multipleSelect]
  );
  const treeContents = useMemo<TreeInfo[]>(() => {
    const convertNodeInfo = ({
      label,
      value,
      children,
    }: TreeNode): TreeInfo => {
      // 生成树形的子 Node
      const childrenNodes = children?.map(convertNodeInfo) ?? [];
      // 获取子 Node 的全部 values
      const childrenValues = children?.reduce(getValueHandle, []) ?? [];
      // 获取未选中的子 Node 列表
      const unCheckedChild = childrenValues.filter(
        (value) => !checkedList.includes(value)
      );

      const checked =
        typeof checkedList === "string"
          ? checkedList === value
          : checkedList.includes(value);
      const indeterminate = checked && unCheckedChild.length > 0;

      return {
        label: isSelect ? (
          <div>{label}</div>
        ) : (
          <Checkbox
            className="!mb-0 !mt-0"
            checked={checked}
            indeterminate={indeterminate}
            onChange={(e) => e.stopPropagation()}
            onClick={(e) => e.stopPropagation()}
            disabled={disabled && !checked}
          >
            {label}
          </Checkbox>
        ),
        isSelected: isSelect ? checked : false,
        hasCaret: !!childrenNodes.length,
        isExpanded: expandKeys.includes(value),
        id: value,
        disabled: disabled && !checked,
        childNodes: childrenNodes,
        nodeData: {
          checked,
          indeterminate,
        },
      };
    };
    return nodes.map(convertNodeInfo);
  }, [expandKeys, checkedList, nodes, isSelect, disabled]);

  return (
    <div className={className}>
      <Tree
        onNodeCollapse={({ id }) => {
          setExpandKeys((state) => state.filter((value) => value !== id));
        }}
        onNodeExpand={({ id }) => {
          setExpandKeys((state) => state.concat(id as string));
        }}
        contents={treeContents}
        onNodeClick={handleClick}
        {...props}
      />
    </div>
  );
};

export default TreeSelect;

const getValueHandle = (prev: string[], item: TreeNode): string[] => {
  const { value, children } = item;
  return prev.concat(value, children?.reduce(getValueHandle, []) ?? []);
};

const getNodeValueHandle = (prev: string[], item: TreeInfo): string[] => {
  const { id, childNodes } = item;
  return prev.concat(
    id as string,
    childNodes?.reduce(getNodeValueHandle, []) ?? []
  );
};

const getParentIds = (
  maps: Map<string, TreeFlatItem<TreeNode>>,
  value: string
): string[] => {
  const list: string[] = [];
  const item = maps.get(value);
  if (item) {
    list.push(item.value);
    if (item.pid !== "") {
      return list.concat(getParentIds(maps, item.pid));
    }
  }
  return list;
};
