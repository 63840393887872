// 维护项目里需要使用到的导航信息
// 用于后台配置菜单及权限相关的内容（即配置生成路由）
import type { PropsWithChildren } from "react";
import { createContext, useMemo, useContext } from "react";

import { useQuery } from "@tanstack/react-query";
import type {
  RefetchOptions,
  QueryObserverResult,
} from "@tanstack/react-query";

/**
 * 初始化 Model 的参数，需要传入两个类型值
 * @N 即 Navigate 的类型
 * @E Error 即错误类型
 */
export interface InitNavigationModelOptions<N, E> {
  key?: string;
  loadNavigate: (data?: any) => Promise<N | null>;
  LoaderComponent?: () => JSX.Element;
  ErrorComponent?: ({ error }: { error?: E }) => JSX.Element;
  waitInitial?: boolean;
}

export interface NavigationContext<N, E> {
  /** 导航信息数据 */
  navList: N | null;
  /** 用于更新导航信息 */
  refetchNavigate: (
    options?: RefetchOptions
  ) => Promise<QueryObserverResult<N | null, E>>;
  /** 是否正在获取信息中 */
  isRefetchingIn: boolean;
  /** 获取用户信息捕捉到的错误 */
  error: E | null;
}

/**
 * 创建 Provider 的参数，泛形 N/E 对应
 * Navigate: 导航类型
 * Error: 错误类型
 * @param config
 */
export function initQueryNavigate<N, E>(
  config: InitNavigationModelOptions<N, E>
) {
  // 解构并创建 config 的默认值
  const {
    key = "navigate",
    waitInitial = true,
    LoaderComponent = () => <div>Loading...</div>,
    ErrorComponent = (error) => <div>{JSON.stringify(error, null, 2)}</div>,
  } = config;
  // 解构需要使用到的函数
  const { loadNavigate } = config;

  // 创建 Context
  const NavigationContext = createContext<NavigationContext<N, E> | null>(null);

  // 创建 Provider
  const NavigationProvider = ({ children }: PropsWithChildren) => {
    const {
      data: navigate,
      error,
      status,
      isLoading,
      isSuccess,
      isPaused,
      refetch,
    } = useQuery<N | null, E>([key], loadNavigate);

    // 设置在 Context 上的值
    const contextValue = useMemo<NavigationContext<N, E>>(
      () => ({
        navList: navigate ?? null,
        error,
        refetchNavigate: refetch,
        isRefetchingIn: isLoading,
      }),
      [navigate, error, refetch, isLoading]
    );

    // 处于 loading 或者暂停的状态下，显示 loaing 状态
    if (isLoading || isPaused) {
      return <LoaderComponent />;
    }
    // 如果请求状态是 success 或者关闭了等待初始化，则显示内容
    if (isSuccess || !waitInitial) {
      return (
        <NavigationContext.Provider value={contextValue}>
          {children}
        </NavigationContext.Provider>
      );
    }

    // 如果用户信息捕捉到了错误，则显示错误状态
    if (error) {
      return <ErrorComponent error={error} />;
    }
    // 如果未满足以上的状态拦截，则显示最终的统一 status
    return <div>Unhandle status: {status}</div>;
  };

  // 暴露给函数式组件的 hooks
  const useNavigation = () => {
    const context = useContext(NavigationContext);
    if (!context) {
      throw new Error("useNavigation must be used within a NavigationProvider");
    }
    return context;
  };

  // Consumer 用于提供给 class 组件去获取 context 中的值
  return {
    NavigationProvider,
    useNavigation,
    NavigationConsumer: NavigationContext.Consumer,
  };
}
