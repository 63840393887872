import create from "zustand";
import { dictApi, DictItem } from "@/service/system/dict";

interface DictStore {
  dictList: DictItem[];
  setDictList: (dictList: DictItem[]) => void;
  initlize: () => Promise<void>;
}

export const useDictStore = create<DictStore>((set) => ({
  dictList: [],
  setDictList: (dictList) => set({ dictList }),
  initlize: async () => {
    const dictList = await dictApi.queryPage({
      pagination: { pageIndex: 0, pageSize: 99999 },
    });
    set({ dictList: dictList as any ?? [] });
  },
}));
