import { useFormContext, Controller } from "@/shared/libs/react-hook-form";
import {
  Switch as SwitchComponent,
  SwitchProps as SwitchComponentProps,
} from "@blueprintjs/core";
import { FieldWrapper, FieldWrapperPassThroughProps } from "../../index";

export type SwitchProps = FieldWrapperPassThroughProps &
  SwitchComponentProps & {
    field: string;
    required?: boolean;
    defaultValue?: boolean;
    switchInline?: boolean;
    text?: string;
  };

export const Switch = ({
  className,
  field,
  required,
  defaultValue,
  label,
  labelNum,
  error,
  inline,
  switchInline,
  text,
  ...switchProps
}: SwitchProps) => {
  const methods = useFormContext();

  return (
    <Controller
      control={methods?.control}
      defaultValue={defaultValue ?? false}
      name={field}
      render={(props) => {
        return (
          <FieldWrapper
            labelNum={labelNum}
            label={label}
            inline={inline}
            required={required}
            error={error ?? methods?.formState.errors[field]}
          >
            <SwitchComponent
              label={text}
              className={className}
              inline={switchInline}
              checked={props.field.value}
              {...switchProps}
              onChange={props.field.onChange}
            />
          </FieldWrapper>
        );
      }}
    />
  );
};
