import { storagePrefix } from "@/config";

export const storage = {
  get: (key: string) => {
    return JSON.parse(localStorage.getItem(`${storagePrefix}${key}`) as string);
  },
  set: (key: string, value: any) => {
    localStorage.setItem(`${storagePrefix}${key}`, JSON.stringify(value));
  },
  clear: (key: string) => {
    localStorage.removeItem(`${storagePrefix}${key}`);
  },
};

export const token = {
  getToken: () => {
    return JSON.parse(localStorage.getItem(`${storagePrefix}token`) as string);
  },
  setToken: (token: string) => {
    localStorage.setItem(`${storagePrefix}token`, JSON.stringify(token));
  },
  clearToken: () => {
    localStorage.removeItem(`${storagePrefix}token`);
  },
};
