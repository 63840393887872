import { ReactNode, cloneElement, CSSProperties } from "react";
import {
  useFormContext,
  UseControllerReturn,
  Controller,
} from "react-hook-form";
import { useFormConfig } from ".";
import { FormGroupProps } from "@blueprintjs/core";

import { FormLabel } from "./Styles";

interface FormItemProps extends Omit<FormGroupProps, "children"> {
  label?: string;
  field: string;
  labelNum?: number;
  inline?: boolean;
  required?: boolean;
  defaultValue?: any | any[];
  className?: string;
  style?: CSSProperties;
  controlError?: boolean;
  children: ((controller: UseControllerReturn) => ReactNode) | JSX.Element;
}

export const FormItem = ({
  label,
  field: name,
  defaultValue = "",
  required,
  children,
  controlError,
  ...itemProps
}: FormItemProps) => {
  const config = useFormConfig();
  const methods = useFormContext();

  return (
    <Controller
      name={name}
      control={methods.control}
      defaultValue={defaultValue}
      render={(control) => {
        const {
          field,
          fieldState: { error },
        } = control;

        return (
          <FormLabel
            {...config}
            label={label}
            required={required}
            intent={error?.message ? "danger" : "none"}
            helperText={
              controlError ? undefined : (error?.message as unknown as string)
            }
            {...itemProps}
          >
            {typeof children === "function"
              ? children(control)
              : cloneElement(children, { ...field })}
          </FormLabel>
        );
      }}
    />
  );
};
