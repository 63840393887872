import { Button } from "@blueprintjs/core";
import { DateInput2, DateInput2Props } from "@blueprintjs/datetime2";
import dayjs from "dayjs";
import { localUtils } from "@/shared/utils/dateUtils";
import "date-fns/locale/zh-CN";
import "dayjs/locale/zh-cn";

export interface DatePickerProps
  extends Omit<DateInput2Props, "formatDate" | "parseDate"> {
  size?: "large" | "small";
  startPlaceholder?: string;
  endPlaceholder?: string;
  formatDate?: DateInput2Props["formatDate"];
  parseDate?: DateInput2Props["parseDate"];
  allowClear?: boolean;
}
const DatePicker = ({
  size,
  placeholder = "请选择日期",
  startPlaceholder = "起始日期",
  endPlaceholder = "截止日期",
  formatDate = (date) => dayjs(date).format("YYYY-MM-DD"),
  parseDate = (string) => dayjs(string).toDate(),
  allowClear = true,
  inputProps,
  ...dateRangeInput2Props
}: DatePickerProps) => {
  return (
    <DateInput2
      popoverProps={{ minimal: true, position: "bottom-left" }}
      dayPickerProps={{
        locale: "zh-cn",
        localeUtils: localUtils,
      }}
      formatDate={formatDate}
      parseDate={parseDate}
      placeholder={placeholder}
      minDate={dayjs().add(-200, "year").toDate()}
      maxDate={dayjs().add(100, "year").toDate()}
      outOfRangeMessage={"时间超出可选择范围"}
      invalidDateMessage="无效的时间日期"
      {...dateRangeInput2Props}
      inputProps={{
        readOnly: !allowClear,
        rightElement:
          !!dateRangeInput2Props.value &&
          allowClear &&
          !dateRangeInput2Props.disabled ? (
            <Button
              icon="cross"
              minimal
              small
              onClick={() => dateRangeInput2Props.onChange?.("", true)}
            />
          ) : undefined,
        ...inputProps,
      }}
    />
  );
};

export default DatePicker;
