import { useFormContext, Controller } from "@/shared/libs/react-hook-form";
import { FieldWrapper, FieldWrapperPassThroughProps } from "../../index";

import MultiSelectComponent, {
  MultiSelectProps as MultiSelectComponentProps,
} from "@/shared/components/MultiSelect";

export type MultiSelectProps = FieldWrapperPassThroughProps &
  Omit<MultiSelectComponentProps, "value" | "onChange"> & {
    field: string;
    required?: boolean;
    defaultValue?: string | number;
    size?: number;
  };

export const MultiSelect = ({
  className,
  field,
  required,
  defaultValue,
  label,
  error,
  inline,
  labelNum,
  ...multiSelectProps
}: MultiSelectProps) => {
  const methods = useFormContext();

  return (
    <Controller
      control={methods?.control}
      defaultValue={defaultValue ?? []}
      name={field}
      render={(props) => {
        return (
          <FieldWrapper
            className={className}
            label={label}
            labelNum={labelNum}
            inline={inline}
            error={error ?? methods?.formState.errors[field]}
            required={required}
          >
            <MultiSelectComponent
              {...multiSelectProps}
              value={props.field.value}
              onChange={(value) => {
                props.field.onChange(value);
              }}
            />
          </FieldWrapper>
        );
      }}
    />
  );
};
