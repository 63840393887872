import request from "@/shared/libs/axios";
import { z } from "zod";

export const changePasswordSchema = z.object({
  password: z.string().min(1, "原密码不能为空"),
  newPassword: z.string().min(1, "新密码不能为空"),
});

export type ChangePasswordDTO = z.infer<typeof changePasswordSchema>;

export const changePassword = async (data: ChangePasswordDTO) => {
  return request.post<string, ChangePasswordDTO>("/sys/auth/password", data);
};
