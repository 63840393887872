import dayjs from "dayjs";
import classNames from "classnames";

import { FormGroup, FormGroupProps } from "@blueprintjs/core";
import { DateRangeInput2, DateRangeInput2Props } from "@blueprintjs/datetime2";

import { shortCuts, localUtils } from "@/shared/utils/dateUtils";
import "date-fns/locale/zh-CN";
import "dayjs/locale/zh-cn";

export interface DateRangePickerProps
  extends Omit<FormGroupProps, "children">,
    Omit<DateRangeInput2Props, "formatDate" | "parseDate" | "shortcuts"> {
  small?: boolean;
  large?: boolean;
  startPlaceholder?: string;
  endPlaceholder?: string;
  formatDate?: DateRangeInput2Props["formatDate"];
  parseDate?: DateRangeInput2Props["parseDate"];
}
export const DateRangePicker = ({
  className,
  contentClassName,
  helperText,
  inline,
  intent,
  label,
  labelFor,
  labelInfo,
  style,
  subLabel,
  small = true,
  large,
  formatDate = (date) => dayjs(date).format("YYYY-MM-DD"),
  parseDate = (string) => dayjs(string).toDate(),
  startPlaceholder = "起始日期",
  endPlaceholder = "截止日期",
  startInputProps,
  endInputProps,
  popoverProps,
  ...dateRangeInput2Props
}: DateRangePickerProps) => {
  return (
    <FormGroup
      className={classNames("!mb-0", className)}
      {...{
        contentClassName,
        helperText,
        inline,
        intent,
        label,
        labelFor,
        labelInfo,
        style,
        subLabel,
      }}
    >
      <DateRangeInput2
        singleMonthOnly
        allowSingleDayRange
        maxDate={dayjs("2099-12-31").toDate()}
        popoverProps={{ minimal: true, ...popoverProps }}
        startInputProps={{
          large,
          small,
          placeholder: startPlaceholder,
          ...startInputProps,
        }}
        endInputProps={{
          large,
          small,
          placeholder: endPlaceholder,
          ...endInputProps,
        }}
        dayPickerProps={{
          locale: "zh-cn",
          localeUtils: localUtils,
        }}
        formatDate={formatDate}
        parseDate={parseDate}
        shortcuts={shortCuts}
        overlappingDatesMessage="重复的日期"
        {...dateRangeInput2Props}
      />
    </FormGroup>
  );
};
