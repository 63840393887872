import { useState } from "react";

export const useGender = () => {
  const [gender] = useState([
    {
      label: "男",
      value: "male",
    },
    {
      label: "女",
      value: "female",
    },
    {
      label: "未知",
      value: "unknow",
    },
  ]);

  return gender;
};
