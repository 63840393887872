import { ComponentProps } from "react";
import classNames from "classnames";
import ZusfLogo from "@/assets/logo-white.png";
import { projectTitle } from "@/config";

export const Logo = ({
  className,
  ...props
}: Omit<ComponentProps<"img">, "src" | "alt">) => {
  return (
    <img
      className={classNames(className, "object-cover")}
      {...props}
      src={ZusfLogo}
      alt={projectTitle}
    />
  );
};
