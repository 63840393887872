import { Routes, Route, Navigate } from "react-router-dom";
import { Switch } from "@blueprintjs/core";
import { AuthLogin } from "./index";
import { LayoutStyled, FormStyled } from "./Styles";

import { storage } from "@/shared/utils/storage";

export const AuthLayout = () => {
  const isTest = !!storage.get("testFlag");
  const handleChangeTestMode = () => {
    storage.set("testFlag", !isTest);
    window.location.reload();
  };
  return (
    <LayoutStyled>
      <FormStyled>
        <div>
          <div className="w-full flex justify-end">
            <Switch
              checked={isTest}
              onChange={handleChangeTestMode}
              innerLabel="正式环境"
              innerLabelChecked="测试环境"
            />
          </div>
          <Routes>
            <Route path="login" element={<AuthLogin />} />
            <Route path="*" element={<Navigate to="login" replace />} />
          </Routes>
        </div>
      </FormStyled>
    </LayoutStyled>
  );
};
