import { useState, useRef, ReactNode } from "react";

import { Overlay, OverlayProps, Classes, Button } from "@blueprintjs/core";
import Draggable, { DraggableEventHandler } from "react-draggable";
import { Resizable, ResizableProps, ResizeCallbackData } from "react-resizable";

import classNames from "classnames";

export interface ModalProps extends Omit<OverlayProps, "onClose"> {
  showHidden?: boolean;
  onHiddenClick?: () => void;
  title?: ReactNode;
  onConfirm?: () => void;
  onClose?: () => void;
  onDrag?: () => void;
  width?: number;
  height?: number;
  zIndex?: number;
  minWidth?: number;
  minHeight?: number;
}
export const Modal = ({
  showHidden = false,
  onHiddenClick,
  title,
  children,
  onConfirm,
  onDrag,
  width = 500,
  height = 212,
  minWidth = 220,
  minHeight = 64,
  zIndex = 100,
  ...props
}: ModalProps) => {
  // 拖拽相关的函数及变量
  const position = useRef<{ x: number; y: number }>();
  const [disabled, setDisabled] = useState(true);
  const [bounds, setBounds] = useState({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  });
  const draggleRef = useRef<HTMLDivElement>(null);
  const onStart: DraggableEventHandler = (_event, uiData) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = draggleRef.current?.getBoundingClientRect();
    if (!targetRect) return;
    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y),
    });
  };

  // Resizable 相关 State
  const [size, setSize] = useState<{ width: number; height: number }>({
    width: width > window.innerWidth ? window.innerWidth : width,
    height: height > window.innerHeight ? window.innerHeight - 60 : height,
  });
  const onResise: ResizableProps["onResize"] = (
    _e: React.SyntheticEvent,
    { size }: ResizeCallbackData
  ) => {
    setSize({ width: size.width, height: size.height });
  };

  return (
    <Overlay
      shouldReturnFocusOnClose={false}
      canOutsideClickClose={false}
      canEscapeKeyClose={false}
      enforceFocus={false}
      autoFocus={false}
      hasBackdrop={false}
      {...props}
    >
      <div className={Classes.DIALOG_CONTAINER} style={{ zIndex }}>
        <Draggable
          disabled={disabled}
          bounds={bounds}
          onStart={onStart}
          onDrag={onDrag?.bind(this, undefined)}
          onStop={(_e, data) => {
            position.current = { x: data.x, y: data.y };
          }}
          defaultPosition={position.current}
        >
          <Resizable
            width={size.width}
            height={size.height}
            maxConstraints={[window.innerWidth, window.innerHeight]}
            onResize={onResise}
            minConstraints={[minWidth, minHeight]}
          >
            <div
              ref={draggleRef}
              onClick={(event) => event.stopPropagation()}
              className={classNames(Classes.DIALOG, "!pb-0")}
              style={{ width: size.width, height: size.height }}
            >
              <div
                className={classNames(Classes.DIALOG_HEADER, "cursor-move")}
                onMouseOut={setDisabled.bind(this, true)}
                onMouseOver={setDisabled.bind(this, false)}
              >
                <div className={Classes.HEADING}>{title}</div>
                {showHidden && (
                  <Button
                    aria-label="hidden"
                    minimal
                    icon={"minus"}
                    onClick={onHiddenClick}
                  />
                )}
                <Button
                  aria-label="Close"
                  className={Classes.DIALOG_CLOSE_BUTTON}
                  minimal
                  icon="cross"
                  onClick={props.onClose}
                />
              </div>
              {children}
            </div>
          </Resizable>
        </Draggable>
      </div>
    </Overlay>
  );
};
