import { useFormContext, Controller } from "@/shared/libs/react-hook-form";
import { NumericInput, NumericInputProps } from "@blueprintjs/core";
import { FieldWrapper, FieldWrapperPassThroughProps } from "../../index";

export type InputNumberProps = FieldWrapperPassThroughProps &
  NumericInputProps & {
    field: string;
    required?: boolean;
    defaultValue?: string | number;
    size?: number;
  };

export const InputNumber = ({
  className,
  field,
  required,
  defaultValue,
  label,
  error,
  inline,
  labelNum,
  ...inputProps
}: InputNumberProps) => {
  const methods = useFormContext();

  return (
    <Controller
      control={methods?.control}
      defaultValue={defaultValue ?? 0}
      name={field}
      render={(props) => {
        return (
          <FieldWrapper
            className={className}
            label={label}
            inline={inline}
            labelNum={labelNum}
            error={error ?? methods?.formState.errors[field]}
            required={required}
          >
            <NumericInput
              {...inputProps}
              value={props.field.value}
              inputRef={props.field.ref}
              onValueChange={(_v) => props.field.onChange(_v)}
            />
          </FieldWrapper>
        );
      }}
    />
  );
};
