import { useRoutes, RouteObject, Outlet, Navigate } from "react-router-dom";

import { Fragment, useMemo } from "react";
import { NavigationType } from "@/shared/api/system/menu";

import Dashboard from "@/pages/Dashboard";
import { componentHash } from "@/routes";

interface IERPRouterProps {
  navList: NavigationType[];
}
export const ERPRouter = ({ navList }: IERPRouterProps) => {
  const routesList = useMemo(() => {
    const buildRouter = (item: NavigationType) => {
      const { children, component, path, type } = item;

      const Component = componentHash[component] ?? Dashboard;

      const route: RouteObject = {
        path: type === 0 ? path + "/*" : path,
        element: type === 0 ? <Outlet /> : <Component key={path} />,
      };

      if (children?.length) {
        route.children = children.map(buildRouter).concat(
          {
            index: true,
            element: <Dashboard />,
          },
          { path: "*", element: <Navigate to="/404" replace /> }
        );
      }

      return route;
    };

    const list: RouteObject[] = navList.map(buildRouter);
    if (navList.length) {
      list.push({
        index: true,
        element: <Dashboard />,
      });
    }
    list.push({ path: "*", element: <Navigate to="/404" replace /> });

    return list;
  }, [navList]);

  const routes = useRoutes(routesList);
  return <Fragment>{routes}</Fragment>;
};
