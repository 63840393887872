import type { ToastProps } from "@blueprintjs/core";
import { toaster } from "@/shared/libs/toaster";
import { useCallback, useMemo } from "react";

/**
 * 顶部弹窗通知的 Hook。
 * 对 toaster 进行封装，返回成 promise 的形式
 */
export const useToast = (basicOption?: Partial<ToastProps>) => {
  const show = useCallback(
    ({ onDismiss, ...option }: ToastProps) => {
      return new Promise<boolean>((resolve) => {
        toaster.current?.show({
          ...basicOption,
          onDismiss: (e) => {
            if (typeof onDismiss === "function") onDismiss(e);
            resolve(true);
          },
          ...option,
        });
      });
    },
    [basicOption]
  );

  const success = useCallback(
    (option: ToastProps | string) => {
      const options = typeof option === "string" ? { message: option } : option;
      return show({ icon: "tick", ...options, intent: "success" });
    },
    [show]
  );
  /**
   * 预置的错误 toaster 风格
   * @option - 传入 toast 的参数
   * @stop - 此参数为 true 则抛出异常，终止后续执行
   */
  const fail = useCallback(
    (option: ToastProps | string, stop?: boolean) => {
      const options = typeof option === "string" ? { message: option } : option;
      const result = show({ icon: "error", ...options, intent: "danger" });
      if (stop) throw options.message;
      return result;
    },
    [show]
  );
  return useMemo(
    () => ({
      show,
      success,
      /**
       * 预置的错误 toaster 风格
       * @option - 传入 toast 的参数
       * @stop - 此参数为 true 则抛出异常，终止后续执行
       */
      fail,
    }),
    [show, success, fail]
  );
};
