import request from "@/shared/libs/axios";
import { z } from "zod";

export const loginSchema = z.object({
  empNo: z
    .string()
    .min(1, "登录账户不能为空")
    .max(10, "登录账户不能超过10个字符"),
  password: z.string().min(1, "账户密码不能为空"),
});

export type LoginCredentialsDTO = z.infer<typeof loginSchema>;

export const login = async (data: LoginCredentialsDTO) => {
  return request.post<string, LoginCredentialsDTO>("/sys/auth/login", data);
};
export const logout = async () => {
  return request.post("/sys/auth/logout");
};
