import styled from "styled-components";
import { storage } from "@/shared/utils/storage";

import { useRef } from "react";
import { UserInfoType } from "@/shared/libs/auth";
import { NavigationType } from "@/shared/api/system/menu";

import { NavLink as NavLinkComponents } from "react-router-dom";
import { Icon, Menu, MenuDivider } from "@blueprintjs/core";
import { Popover2, MenuItem2 } from "@blueprintjs/popover2";
import { Logo } from "@/components";

import { logout } from "@/shared/api/system/auth";

interface IHeaderProps {
  user: UserInfoType;
  navList: NavigationType[];
  handleChangePassword: () => void;
  hanldeLogOut: () => void;
}
export const Header = ({
  navList,
  user,
  handleChangePassword,
  hanldeLogOut,
}: IHeaderProps) => {
  const isTest = useRef<boolean>(!!storage.get("testFlag"));

  const handleLogOut = async () => {
    await logout();
    hanldeLogOut();
  };
  const handleChangeTestFlag = () => {
    storage.set("testFlag", !isTest.current);
    handleLogOut();
  };

  return (
    <div
      className="h-48px flex-shrink-0 bg-dark-400 text-white flex items-center px-2"
      style={{ background: isTest.current ? "#ffa62f" : undefined }}
    >
      <div className="w-[100px]">
        <Logo className="h-8" />
      </div>
      <div className="flex-grow flex items-center justify-center">
        {navList.map((item) => (
          <NavLink
            className={isTest.current ? "test" : undefined}
            to={item.path}
            key={item.path}
          >
            {item.name}
          </NavLink>
        ))}
      </div>
      <div className="w-[100px]">
        <Popover2
          content={
            <Menu>
              <MenuItem2
                icon="key"
                text="修改密码"
                onClick={() => handleChangePassword()}
              />
              <MenuDivider />
              <MenuItem2
                icon={isTest.current ? "build" : "lab-test"}
                text={isTest.current ? "前往正式系统" : "前往测试系统"}
                onClick={() => handleChangeTestFlag()}
              />
              <MenuItem2
                icon="log-out"
                text="退出系统"
                onClick={() => hanldeLogOut()}
              />
            </Menu>
          }
          minimal
          interactionKind="hover"
          position="bottom-right"
          renderTarget={({ isOpen, ref, ...targetProps }) => {
            return (
              <div {...targetProps} className="flex cursor-pointer" ref={ref}>
                <div>{user.name}</div>
                <div className="flex-grow" />
                <Icon
                  icon={isOpen ? "caret-up" : "caret-down"}
                  size={14}
                  className="cursor-pointer"
                />
              </div>
            );
          }}
        />
      </div>
    </div>
  );
};

const NavLink = styled(NavLinkComponents)`
  all: unset;
  padding: 8px 12px;
  margin: 0 2px;
  color: white;
  white-space: nowrap;

  &:hover,
  &.active {
    color: white;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    text-decoration: none;

    &.test {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
`;
