import Api from "@/service/common/api";
import { DictItem } from "./type";

export * from "./type";

class Dict extends Api<DictItem> {
  constructor() {
    super({
      controller: "dict",
      dbAbbr: "dt",
      apiMap: {
        queryPage: "list",
      },
    });
  }
}

export const dictApi = new Dict();
