import type { PropsWithChildren, ComponentProps } from "react";
import {
  FormProvider,
  SubmitHandler,
  SubmitErrorHandler,
  UseFormReturn,
  FieldValues,
} from "react-hook-form";

import { useToast } from "@/shared/hooks";

export interface FormProps<T extends FieldValues>
  extends Omit<ComponentProps<"form">, "onSubmit" | "onError"> {
  methods: UseFormReturn<T>;
  onSubmit?: SubmitHandler<T>;
  onError?: SubmitErrorHandler<T>;
}

export const Form = <T extends FieldValues = FieldValues>({
  methods,
  onSubmit = () => {},
  onError,
  children,
  ...formProps
}: PropsWithChildren<FormProps<T>>) => {
  const { fail } = useToast({ timeout: 1000 });
  const defaultErrorHandler: SubmitErrorHandler<T> = (e) => {
    console.error(e);
    const [first] = Object.keys(e);
    const value = e[first];
    if (Array.isArray(value) && value.length) {
      const [valueFirst] = value.filter((item) => !!item);
      if (!valueFirst) return;
      if (valueFirst?.message) {
        fail(valueFirst?.message as string);
      } else {
        const [valueFirstKey] = Object.keys(valueFirst);
        fail(valueFirst[valueFirstKey]?.message as string);
      }
    } else {
      fail(value?.message as string);
    }
  };
  const handleSubmit: ComponentProps<"form">["onSubmit"] = (event) => {
    event.stopPropagation();
    return methods.handleSubmit(
      onSubmit,
      onError ?? defaultErrorHandler
    )(event);
  };

  return (
    <FormProvider {...methods}>
      <form {...formProps} onSubmit={handleSubmit}>
        {children}
      </form>
    </FormProvider>
  );
};
