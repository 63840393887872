import { useCallback, useEffect } from "react";
import { projectTitle } from "@/config";

export const useTitle = (title?: string) => {
  const set = useCallback((title: string) => {
    document.title = `${title} - ${projectTitle}`;
  }, []);
  
  useEffect(() => {
    if (!title) return;
    set(title);
    return;
  }, [title, set]);

  return set;
};
