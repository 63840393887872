const Dashboard = () => {
  return (
    <div className="w-full h-full flex flex-col">
      <div className="p-4 text-center">
        <h2 className="text-2xl mb-1 font-bold">朱师傅预混饲料事业有限公司</h2>
        <p className="text-gray-500">朱师傅预混饲料事业有限公司 ERP 管理系统</p>
      </div>
    </div>
  );
};

export default Dashboard;
