import React from "react";
import { FormLabel } from "./Styles";

export type FieldWrapperProps = {
  label?: string;
  inline?: boolean;
  labelNum?: number;
  required?: boolean;
  disabled?: boolean;
  className?: string;
  children: React.ReactNode;
  error?: any;
};

export type FieldWrapperPassThroughProps = Omit<
  FieldWrapperProps,
  "className" | "children"
>;

export const FieldWrapper = ({
  label,
  error,
  children,
  required,
  disabled,
  ...props
}: FieldWrapperProps) => {
  
  return (
    <FormLabel
      disabled={disabled}
      label={label}
      required={required}
      helperText={error?.message}
      intent={error?.message ? "danger" : undefined}
      {...props}
    >
      {children}
    </FormLabel>
  );
};
