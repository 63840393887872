import { useFormContext, Controller } from "@/shared/libs/react-hook-form";
import { InputGroup, InputGroupProps } from "@blueprintjs/core";
import { FieldWrapper, FieldWrapperPassThroughProps } from "../../index";

export type InputProps = FieldWrapperPassThroughProps &
  InputGroupProps & {
    field: string;
    required?: boolean;
    defaultValue?: string | number;
  };

export const Input = ({
  type,
  className,
  field,
  required,
  defaultValue,
  label,
  error,
  inline,
  labelNum,
  ...inputProps
}: InputProps) => {
  const methods = useFormContext();

  return (
    <Controller
      control={methods?.control}
      defaultValue={defaultValue ?? ""}
      name={field}
      render={(props) => {
        return (
          <FieldWrapper
            label={label}
            inline={inline}
            labelNum={labelNum}
            className={className}
            error={error ?? methods?.formState.errors[field]}
            required={required}
          >
            <InputGroup
              autoComplete={type === "password" ? "new-password" : "off"}
              type={type}
              placeholder="请输入"
              {...inputProps}
              {...props.field}
            />
          </FieldWrapper>
        );
      }}
    />
  );
};
