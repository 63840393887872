// 项目的路由入口文件
// 此 Route 仅定义了基础的路由即：登录路由 /auth 、项目指向路由 /project 及 404 等公用路由
// 具体 Route 需查看路由对应组件的 Routes
import { Fragment } from "react";
import { useRoutes } from "react-router-dom";
import type { RouteObject } from "react-router-dom";

import { useAuth } from "@/shared/libs/auth";

import LandingPage from "@/features/Landing";
import { ERPLayout } from "@/features/ERPLayout";
import { publicRoutes, notFoundRoute } from "./public";

// 获取 pages 文件夹下的所有组件，暴露给 project 进行配置式路由生成
export * from "./component";

const commonRoutes: RouteObject[] = [{ path: "/", element: <LandingPage /> }];

export const AppRoutes = () => {
  const { user } = useAuth();
  // const isTest = useRef<boolean>(!!storage.get("testFlag"));

  const routes = useRoutes(
    [...commonRoutes, ...publicRoutes].concat(
      user
        ? [
            {
              path: "/project/*",
              element: <ERPLayout />,
              // element: isTest.current ? <ERPLayout /> : <ProjectLayout />,
            },
            { path: "/new-project/*", element: <ERPLayout /> },
          ]
        : [],
      [notFoundRoute]
    )
  );

  return <Fragment>{routes}</Fragment>;
};
