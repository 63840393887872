import request from "@/shared/libs/axios";
import type { WhereConditionInterface, ListResult } from "@/shared/types/data";
import { z, Schema } from "zod";

type CRUDModelOptions<S extends Schema = Schema> = {
  model: string;
  controller: string;
  schema: S;
  apiMap?: Record<string, string>;
  dbAbbr?: string;
};

type BasicType = Record<string, any>;

export class CRUDModel<Type = BasicType, S extends Schema = Schema> {
  private controller: string;
  private model: string;
  private apiMap: Record<string, string> = {
    read: "queryPage",
    create: "insert",
    update: "updateById",
    delete: "deleteById",
    delBatch: "deleteBatchByIds",
    find: "findById",
    cancel: "cancelDoc",
  };
  private dbAbbr: string = "";
  private schema: S;

  constructor({
    controller,
    model,
    apiMap,
    dbAbbr,
    schema,
  }: CRUDModelOptions<S>) {
    this.model = model;
    this.controller = controller;
    this.schema = schema;
    if (apiMap) this.apiMap = Object.assign({}, this.apiMap, apiMap);
    if (dbAbbr) this.dbAbbr = dbAbbr;
  }

  getUrl = (key: string) => {
    return ["", this.controller, this.model, this.apiMap[key] ?? key].join("/");
  };

  queryPage = (param: WhereConditionInterface) => {
    return request.post<ListResult<Type>>(this.getUrl("read"), param);
  };

  find = (id: string) => {
    return request.get<Type>(this.getUrl("find") + `/${id}`);
  };

  edit = (data: z.infer<S>) => {
    const url = this.getUrl(data.id ? "update" : "create");
    return request.post(url, data);
  };

  del = (id: string) => {
    return request.get(this.getUrl("delete") + `/${id}`);
  };

  cancel = (id: string) => {
    return request.get(this.getUrl("cancel") + `/${id}`);
  };

  delBatch = (id: string | string[]) => {
    const payload: string[] = [];
    return request.post(this.getUrl("delBatch"), payload.concat(id));
  };

  getConditionAbbr = () => {
    if (!!this.dbAbbr) return this.dbAbbr;
    return [this.controller, this.model]
      .join("/")
      .split("/")
      .map((item) => item[0])
      .join("");
  };

  getSchema = () => {
    return this.schema;
  };
}
